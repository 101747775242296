/* eslint-disable max-classes-per-file */
import popupStyle from 'ol-ext/overlay/Popup.css';
import {
  getQueryObject,
  i18nMixin,
  responsiveMixin,
  ViewPort,
  SM,
} from '@kisters/wcp-base/decorators';
import { fromLonLat } from 'ol/proj';
import { html, LitElement, unsafeCSS } from 'lit';
import { customElement, property, queryAssignedNodes } from 'lit/decorators.js';
import { Map, View } from 'ol';
import { inAndOut } from 'ol/easing';
import {
  Mix,
  LoaderMixin,
  setWithExpiry,
  getWithExpiry,
} from '@kisters/wcp-base/common';
import { flatten, debounce } from 'lodash-es';
import olcss from 'ol/ol.css';
import ovStyle from 'ol-ext/control/Overview.css';
import Popup from 'ol-ext/overlay/Popup';
import { Attribution, defaults as defaultControls } from 'ol/control';
import { getLayer } from '../../common/ki-ol-layer-generator';
import style from './ki-station-map.css';
import './ki-ol-layer';
import nls from '../../locales/index';

export interface OLStyle {
  background: String;
  icon: String;
  fontSize: Number;
  radius: Number;
  color: String;
  border: Object;
}
export interface OLLayer {
  type: 'topojson' | 'GeoJSON' | 'osm' | 'layergroup' | 'xyz';
  url: string;
  label: string;
  group: string;
  visible: Boolean;
  opacity: Number;
  projection: string;
  zIndex: Number;
  selectable: Boolean;
  viewFilter: Array<string>;
  maxZoom: Number;
  minZoom: Number;
  getOlLayer: Function;
}

export interface VectorOLLayer extends OLLayer {
  style: OLStyle;
  hoverStyle: OLStyle;
  textStyle: Object;
  labelProperty: string;
}

@customElement('ki-station-map')
export default class KiStationMap extends Mix(
  LitElement,
  [i18nMixin, { nls }],
  responsiveMixin,
  LoaderMixin,
) {
  static styles = [
    style,
    unsafeCSS(olcss),
    unsafeCSS(popupStyle),
    unsafeCSS(ovStyle),
  ];

  @property({ type: String })
  cachePrefix = 'none';

  @property({ type: Object })
  view = {
    projection: 'EPSG:3857',
    center: [0, 0],
    zoom: 2,
  };

  @property({ type: Array })
  olLayers = [];

  @property({ type: Array })
  layers = [{ type: 'osm', visible: true, label: 'Openstreetmap' }];

  @property({ type: Boolean })
  disableMouseOver = false;

  @property({ type: Boolean })
  hideAttributions = false;

  @property({ type: Number })
  persistancetimeout = 3600;

  @queryAssignedNodes({ slot: 'layer', flatten: true })
  layerElements!: Array<HTMLElement>;

  olLayerList: any = [];

  firstUpdated() {
    this._createMap();
  }

  get _view() {
    return new View(this.view);
  }

  get _layers() {
    return this.layerElements
      .map(layer => layer.getOlLayer())
      .concat(this.layers.map(layer => getLayer(layer)));
  }

  get __mapElement() {
    return getQueryObject(this, '#map');
  }

  get __infoElement() {
    return getQueryObject(this, '#scale-info');
  }

  _createMap() {
    this.map = new Map({
      target: this.__mapElement,
      view: this._view,
      controls: this.hideAttributions
        ? []
        : defaultControls({ zoom: false, attribution: false }).extend([
            new Attribution({
              collapsible: false,
            }),
          ]),
    });

    const readyEvent = new CustomEvent('kistationmapready');

    //  this.map.once('rendercomplete', () => {
    this.dispatchEvent(readyEvent);
    this.setMapEvents();
    // });

    this.map.on('precompose', evt => {
      if (evt.context) {
        evt.context.imageSmoothingEnabled = false;
        evt.context.webkitImageSmoothingEnabled = false;
        evt.context.mozImageSmoothingEnabled = false;
        evt.context.msImageSmoothingEnabled = false;
      }
    });
    this.map.once('postrender', () => {
      this.retry = setInterval(() => {
        if (!this.map.isRendered()) {
          this.resize();
        } else {
          clearInterval(this.retry);
        }
      }, 1000);
    });

    // This can't be done with once because if the map size is 0,0 then it will zoom to an incredibly close extent.
    // If the first postrender happens while the map is not displayed then the size will be 0,0.
    const loadCacheZoom = () => {
      const size = this.map.getSize();
      // wait until the map is ready
      if (size && size.length > 1 && size[0] !== 0 && size[1] !== 0) {
        this.initExtent();
        // remove the event because it should only happen once at the start
        this.map.un('postrender', loadCacheZoom);
      }
    };
    if (this.persistancetimeout) {
      this.map.on('postrender', loadCacheZoom);
    }
  }

  initExtent() {
    const extent = this.persistancetimeout
      ? getWithExpiry(`${this.cachePrefix}-ki-station-map-zoom`, true)
      : null;
    if (extent) {
      this.map.getView().fit(extent, this.map.getSize());
    }
  }

  setMapEvents() {
    this._initPopup();

    const popupelement = document.createElement('div');
    popupelement.style.padding = '10px';
    let selected = null;
    let tooltip = null;

    this.map.on('moveend', evt => {
      const { map } = evt;
      const extent = map.getView().calculateExtent(map.getSize());
      this.dispatchEvent(
        new CustomEvent('extentchange', {
          bubbles: true,
          composed: true,
          detail: {
            extent,
          },
        }),
      );
      if (this.persistancetimeout) {
        setWithExpiry(
          `${this.cachePrefix}-ki-station-map-zoom`,
          extent,
          this.persistancetimeout,
          true,
        );
      }
    });

    this.map.on('singleclick', evt => {
      this.map.forEachFeatureAtPixel(
        evt.pixel,
        feature => {
          if (feature.get('url')) {
            window.open(feature.get('url'), '_blank');
            return true;
          }
          return false;
        },
        { layerFilter: layer => layer.get('haslinks') },
      );
    });
    /* eslint-disable-next-line consistent-return */
    this.map.on('pointermove', evt => {
      this.map.getTargetElement().style.cursor = '';
      if (this.disableMouseOver) {
        if (tooltip !== null) {
          this._popup.hide();
          tooltip = null;
        }
        return false;
      }
      if (selected !== null) {
        selected.setStyle(selected._style);
        selected = null;
      }
      if (tooltip !== null) {
        this._popup.hide();
        tooltip = null;
      }
      this.map.forEachFeatureAtPixel(
        evt.pixel,
        feature => {
          if (feature.get('url')) {
            this.map.getTargetElement().style.cursor = 'pointer';
          }

          if (feature._toolTip && !this._popup.getVisible()) {
            popupelement.innerHTML = feature._toolTip;
            this._popup.show(
              this.map.getCoordinateFromPixel(evt.pixel),
              popupelement,
            );
            tooltip = true;
          }
          if (feature._hoverStyle) {
            selected = feature;
            feature.setStyle(feature._hoverStyle);
            return true;
          }
          return false;
        },
        {
          layerFilter: layer =>
            layer.get('haslinks') || layer.get('interactive'),
        },
      );
    });
  }

  disconnectedCallback() {
    if (super.disconnectedCallback) super.disconnectedCallback();
    this.retry && clearInterval(this.retry);
  }

  updated(changedProperties) {
    super.update(changedProperties);
    if (this.map) {
      if (changedProperties.has('view')) {
        this.map.setView(this._view);
      }

      if (changedProperties.has('layers')) {
        this.map
          .getLayers()
          .extend(flatten(this._layers).concat(this.olLayers));
      }
    }
  }

  render() {
    // language=html
    this.disableMouseOver = this.classList.contains('sm-screen');
    return html`
      <slot name="layer" style="display:none;"></slot>
      <div id="map"></div>
      ${this._renderLoader()}
    `;
  }

  gotoLocation() {
    // TODO timeout
    // need https
    const p = new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        pos => {
          this.map.getView().animate({
            center: fromLonLat(
              [pos.coords.longitude, pos.coords.latitude],
              this.map.getView().getProjection(),
            ),
            duration: 500,
          });
          resolve();
        },
        e => {
          console.error(e);
          reject(e);
        },
        {
          timeout: 2000,
        },
      );
    });
    // TODO
    this.promiseLoader(p);
  }

  resize() {
    if (this.map) {
      this.__mapElement.style.width = 'auto';
      this.__mapElement.style.transform = '';
      this.map.updateSize();
    }
  }

  getView() {
    return this.map.getView();
  }

  zoomTo(extent, duration = 0, padding = 100) {
    this.map.getView().fit(extent, {
      nearest: true,
      easing: inAndOut,
      padding:
        ViewPort.size === SM && padding
          ? [20, 20, 20, 20]
          : [padding, padding, padding, padding],
      size: this.map.getSize(),
      duration,
    });
  }

  // move to ki-map-popup
  _initPopup() {
    const popup = new Popup({
      positioning: 'top-right',
      closeBox: false,
    });
    this._popup = popup;
    this.map.addOverlay(popup);

    const showPopup = e => {
      if (!this.map.disablePopup) {
        const { coordinate, element, stations, layerAlias } = e.detail;
        if (popup.getVisible() && !popup.element.stations) {
          popup.hide();
        }
        const p = this.map.getPixelFromCoordinate(coordinate);
        const s = this.map.getSize();
        const horizontal = p[0] > s[0] / 2 ? 'right' : 'left';
        const vertical = p[1] > s[1] / 2 ? 'bottom' : 'top';

        popup.setPositioning(`${vertical}-${horizontal}`);
        element.stations = stations;
        element.layerAlias = layerAlias;
        popup.show(coordinate, element);
      }
    };

    const closePopup = e => {
      const { element } = e.detail;
      element.stations = null;
      popup.hide();
    };

    this.map.on('show-popup', debounce(showPopup, 100));
    this.map.on('close-popup', debounce(closePopup, 100));
    // show pointer
  }
}
