import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { Mix } from '@kisters/wcp-base/common';
import { i18nMixin } from '@kisters/wcp-base/decorators';
import dayjs from '@kisters/wcp-base/common/dayjsext';
import Highcharts from 'highcharts/highstock';
import { groupBy, find } from 'lodash-es';
import { ExternalStation } from '@kisters/wcp-water/common';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import hclocales from '@kisters/wcp-water/common/highcharts-locales.js';
import nls from '../../locales/index';
import { getCurrentApi } from '../../api/API';

// eslint-disable-next-line import/extensions

@customElement('ww-map-popup')
export default class WwMapPopup extends Mix(LitElement, [i18nMixin, { nls }]) {
  static styles = css`
    :host {
      display: block;
      z-index: 100;
    }
    .station-name {
      padding-right: 5px;
      padding-left: 10px;
      padding-top: 10px;
      font-size: 1.2em;
    }

    hr {
      margin: 5px 0;
    }
    .table {
      display: table;
      line-height: 1.5em;
    }
    .row {
      display: table-row;
    }

    .hidden {
      display: none !important;
    }

    .highlight {
      font-weight: bold;
    }

    .cell {
      display: table-cell;
      padding: 0 10px;
      min-width: 50px;
      color: gray;
    }

    .label {
      color: #4a4a49;
    }

    .highcharts-label {
      display: none;
    }

    #chartcontainer {
      border-top: 1px dotted lightgray;
      height: 200px;
      min-width: 360px;
      width: 100%;
    }

    .remark {
      padding-left: 10px;
      color: red;
      max-width: 100%;
    }
  `;

  @property({ type: Array })
  stations: Array<object> = [];

  @property({ type: String })
  stationRemark; // Additional comment in left Menu

  constructor() {
    super();
    if (hclocales[this.i18n.language.substring(0, 2)]) {
      Highcharts.setOptions({
        lang: hclocales[this.i18n.language.substring(0, 2)],
      });
    }
    this.stations = [];
    this.api = getCurrentApi();
    const formatter = new Intl.NumberFormat(this.i18n.language, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    this.valFormatter = {
      format(val) {
        return val === 0 ? val : formatter.format(val);
      },
    };
  }

  firstUpdated() {
    const node = this.shadowRoot.querySelector('#chartcontainer');
    if (node) {
      this.chart = new Highcharts.Chart(node, {
        chart: {
          animation: false,
        },
        title: {
          text: '',
        },
        subtitle: {
          text: '',
        },
        exporting: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        labels: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          type: 'datetime',
        },
        yAxis: [
          {
            id: 'mainYAxis',

            title: {
              text: '',
            },
          },
          {
            id: 'sumYAxis',
            title: {
              text: '',
            },
            opposite: true,
          },
        ],
        plotOptions: {
          series: {
            animation: false,
            label: {
              enabled: false,
            },
          },
        },
        series: [
          {
            id: 'preview',
            data: [],
            label: {
              enabled: false,
            },
          },
          {
            id: 'linehelper',
            type: 'scatter',
            data: [],
            showInLegend: false,
            marker: {
              enabled: false,
            },
            enableMouseTracking: false,
          },
        ],
      });
    }
  }

  /* eslint-disable-next-line class-methods-use-this */
  getSeries(data, tsShortname) {
    const ser = find(data, { ts_shortname: tsShortname }) || data[0];
    if (ser) {
      ser.data = ser.data.map(item => [new Date(item[0]).getTime(), item[1]]);
    }
    return ser;
  }

  async _setChartData(station) {
    if (this.chart && this.lastStationId !== station.station_id) {
      this.chart.showLoading();
      const series = this.chart.get('preview');
      series.setData([]);
      const mainParameter =
        station.mainParameter || Object.keys(station.ts_values)[0];
      const parameter = station.ts_values[mainParameter];
      this.lastStationId = station.station_id;
      const siteNo = station.site_no || station.ts_path?.split('/')[0] || 0;
      const stationparameterNo =
        parameter.stationparameter_no || parameter.ts_path?.split('/')[2];
      const data = await this.api.getTsData(
        `internet/stations/${siteNo}/${station.station_no}/${stationparameterNo}/week.json`,
        false,
      );
      const sdata = this.getSeries(data, parameter.ts_shortname).data;
      series.update({ type: 'line', data: sdata });

      this.chart.hideLoading();
    }
  }

  /* eslint-disable-next-line class-methods-use-this */
  _getRow(station) {
    const parameter =
      station.stationparameter_longname || station.stationparameter_name;
    const value =
      station.ts_value != null
        ? `${this.valFormatter.format(station.ts_value)} ${
            station.ts_unitsymbol
          }`
        : '-';
    const timestamp = station.timestamp
      ? dayjs(station.timestamp).tz().format('L LT')
      : '-';
    return html`<div class="row">
      <div class="cell label">${parameter}</div>
      <div class="cell"><b>${value}</b> (${timestamp})</div>
    </div>`;
  }

  _valueTmpl() {
    const station = this.stations[0];
    const river = station.WTO_OBJECT ? `(${station.WTO_OBJECT})` : '';
    if (station.external) return this._stationExternal(station);
    return html`<div class="station-name">
        ${station.station_no} | ${station.station_name} ${river}
      </div>
      <hr />
      <div class="table">
        ${Object.values(station.ts_values)
          .reverse()
          .map(st => this._getRow(st))}
      </div> `;
  }

  _stationTmpl(stations) {
    const station = stations[0];
    const river = station.WTO_OBJECT ? `(${station.WTO_OBJECT})` : '';
    if (station.external) return this._stationExternal(station);
    if (this.stations.length < 2) {
      this._setChartData(station);
    }
    return html`<div class="station-name">
        ${station.station_no} | ${station.station_name} ${river}
      </div>
      <div class="remark">${this.stationRemark}</div>
      <hr />
      <div class="table">
        ${station.ts_values
          ? Object.values(station.ts_values)
              .reverse()
              .map(st => this._getRow(st))
          : stations.map(st => this._getRow(st))}
      </div> `;
  }

  /* eslint-disable-next-line class-methods-use-this */
  _stationExternal(station: ExternalStation) {
    return html`<div class="station-name">${station.station_name}</div>
      <hr />
      <div class="table">
        <div class="row">
          <div class="cell">${unsafeHTML(station.description)}</div>
        </div>
      </div> `;
  }

  render() {
    if (this.stations && this.stations[0] && this.stations[0].mainParameter)
      return this._render();
    return this._renderLegacy();
  }

  _render() {
    return html`${this.stations[0] &&
    this.stations[0].ts_values &&
    Object.keys(this.stations[0].ts_values).length > 1
      ? this._valueTmpl()
      : Object.values(groupBy(this.stations, 'station_id')).map(station =>
          this._stationTmpl(station),
        )}
    ${(Object.keys(this.stations[0].ts_values).length < 2 &&
      !this.stations[0].external) ||
    this.stations[0].mainParameter
      ? '<div id="chartcontainer"></div>'
      : ''} `;
  }

  _renderLegacy() {
    return html`${this.stations &&
      this.stations[0] &&
      this.stations[0].ts_values &&
      Object.keys(this.stations[0].ts_values).length > 1
        ? this._valueTmpl()
        : Object.values(groupBy(this.stations, 'station_id')).map(station =>
            this._stationTmpl(station),
          )}
      <div
        id="chartcontainer"
        style="display:${this.stations?.length === 1 &&
        !this.stations[0].external
          ? 'block'
          : 'none'}"
      ></div>`;
  }
}
