import { css, html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
// eslint-disable-next-line import/extensions
import { property } from 'lit/decorators/property.js';
import { i18nMixin } from '@kisters/wcp-base/decorators';
import { getCurrentApi } from '@kisters/wiski-web/api';
import { Mix } from '@kisters/wcp-base/common';
import Highcharts from 'highcharts/highstock';
import nls from '../locales/index';

@customElement('ki-tceq-graph')
export default class KiTceqGraph extends Mix(LitElement, [i18nMixin, { nls }]) {
  // language=CSS
  static styles = css`
    /* :host {
      overflow: auto;
      display: flex;
      flex-wrap: wrap;
    } */
    #graph-container {
      margin: 40px 20px;
      width: 100%;
      height: 500px;
    }
  `;

  updated() {
    Highcharts.chart(this.renderRoot.querySelector('#graph-container'), {
      chart: {
        panning: {
          enabled: true,
          type: 'xy',
        },
        panKey: 'alt',
        zoomType: 'xy',
      },
      navigator: {
        enabled: true,
      },
      title: {
        text: this.title,
      },
      subtitle: {
        text: this.station.station_name,
      },
      accessibility: {
        screenReaderSection: {
          beforeChartFormat:
            '<{headingTagName}>{chartTitle}</{headingTagName}><div>{chartSubtitle}</div><div>{chartLongdesc}</div><div>{xAxisDescription}</div><div>{yAxisDescription}</div>',
        },
      },
      tooltip: {
        valueDecimals: 2,
      },
      xAxis: {
        type: 'datetime',
      },
      time: {
        timezoneOffset: 6 * 60,
      },
      series: this.data.graph?.map(d => ({
        name: `${d.param_name} (POC: ${d.param_no.slice(-1)})`,
        data: d.data,
        lineWidth: 2.5,
        marker: { enabled: false },
        label: {
          enabled: false,
        },
      })),
      credits: {
        text: '',
        position: {
          x: -10,
          y: -35,
        },
        style: {
          fontSize: '10px',
        },
        href: '',
      },
    });
  }

  @property()
  station;

  @property()
  data;

  @property()
  title;

  @property({ type: Object })
  api = getCurrentApi();

  render() {
    return html` <div id="graph-container"></div>`;
  }

  // eslint-disable-next-line class-methods-use-this, no-empty-function
  async fetchData() {}
}
