/* eslint-disable max-classes-per-file */
import { filter, countBy, pickBy, every, orderBy } from 'lodash-es';

import dayjs from '@kisters/wcp-base/common/dayjsext';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  navigateTo,
  getRouteOptionsAndParams,
} from '@kisters/wcp-base/components';
import {
  localStorageMixin,
  responsiveMixin,
  i18nMixin,
  getQueryObject,
  queryParamMixin,
  ViewPort,
  SM,
} from '@kisters/wcp-base/decorators';
import {
  LoaderMixin,
  Mix,
  PropertyDefaultValue,
} from '@kisters/wcp-base/common';

import LegendFactory from '@kisters/wcp-water/components/ki-station-map/LegendFactory';
import Classifier from '@kisters/wcp-water/components/ki-station-map/Classifier';
import { StatusIcon } from '@kisters/wcp-water/components/ki-status-icon/ki-status-icon';
import { getTrendArrow } from '@kisters/wcp-water/common/base';

import { html, LitElement } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import '@ui5/webcomponents/dist/Toast';

import { getCsvDelimiter } from '@kisters/wcp-base/components/ki-app/services/ki-app-config-service';

import { getCurrentApi } from '@kisters/wiski-web/api/API';

import nls from '@kisters/wiski-web/locales/index';
import style from '@kisters/wiski-web/views/wwp-overview/wwp-overview.css';

import { reproject, registerProjections } from '@kisters/wiski-web/common/maputils';
import '@kisters/wiski-web/components/ki-responsive-table/ki-responsive-table';

import '@kisters/wiski-web/components/ki-wcp-wwp-filter/ki-wcp-wwp-filter';
import '@kisters/wiski-web/components/common/ww-map-popup';
import '@kisters/wiski-web/components/common/ww-map-popup-auto';
import '@kisters/wiski-web/components/common/ww-map-popup-base';
import '@kisters/wiski-web/components/common/ww-map-popup-precip';
import '@kisters/wiski-web/components/common/ww-map-popup-dynamic';

import '@kisters/wiski-web/components/ki-wwp-graph/ki-wwp-sparkline';
import { SortedBy } from '@kisters/wiski-web/views/SortedBy';

@customElement('wwp-tceq-air-overview')
export default class WwpTceqAirOverview extends Mix(
  LitElement,
  LoaderMixin,
  PropertyDefaultValue,
  [
    localStorageMixin,
    { targetProperty: '__favouriteStationIds', typeParam: Array },
  ],
  [
    queryParamMixin,
    { targetProperty: 'mapOrTable', selector: 'mode', defaultValue: 'map' },
  ],
  [
    queryParamMixin,
    {
      targetProperty: 'filterContent',
      selector: 'filter',
      defaultValue: '{}',
    },
  ],
  [
    queryParamMixin,
    {
      targetProperty: 'searchContent',
      selector: 'search',
      defaultValue: '',
    },
  ],
  responsiveMixin,
  [i18nMixin, { nls }],
) {
  @query('#map')
  stationMap;

  static styles = style;

  static get properties() {
    return {
      strictSearch: { type: Boolean },
      layersToggleAll: { type: Boolean },
      layersTreeList: { type: Boolean },
      layerName: { type: String },
      showCsvExport: { type: Boolean },
      _dataLayer: { type: Object },
      quickFilters: { type: Array, default: [] },
      homeExtent: { type: Array, default: [] },
      positionProjections: { type: Array, default: [] },
      _legends: { type: Array, default: [] },
      displayStationsInLayerControl: {
        type: Boolean,
        default: true,
        attribute: false,
      },
      _view: {
        type: Object,
        default: {
          projection: 'EPSG:3857',
          zoom: 8,
        },
        attribute: false,
      },
      layerGroups: {
        type: Array,
        default: [
          'Layers',
          'Information',
          { name: 'Backgrounds', type: 'radio' },
        ],
        attribute: false,
      },
    };
  }

  layersToggleAll = false;

  layersTreeList = false;

  _firstWWVisit = 'yes';

  _enableAutoZoom = false;

  tzFormat = 'zzz';

  zoomReset = false;

  refreshTimer = true;

  _filterContent = '{}';

  currentStationExtent = null;

  mapsLoaded = false;

  @property({ type: Boolean })
  hideFavoriteButton = false;

  get filterContent() {
    return this._filterContent;
  }

  set filterContent(value) {
    this._filterContent = value;
    this.requestUpdate();
  }

  // eslint-disable-next-line no-undef
  cachePrefix = __APP_NAME__;

  // eslint-disable-next-line no-undef
  version = __APP_VERSION__;

  _searchContent = '';

  get searchContent() {
    return this._searchContent;
  }

  set searchContent(value) {
    this._searchContent = value;
    this.requestUpdate();
  }

  _mapOrTable = 'map';

  api = getCurrentApi();

  get mapOrTable() {
    return this._mapOrTable;
  }

  set mapOrTable(value) {
    this._mapOrTable = value;
    this.requestUpdate();
  }

  ___favouriteStationIds = [];

  get __favouriteStationIds() {
    return this.___favouriteStationIds;
  }

  set __favouriteStationIds(value) {
    this.___favouriteStationIds = value;
    this.requestUpdate();
  }

  get searchBox() {
    return getQueryObject(this, '#searchbox');
  }

  overViewLayer = {
    layer: [],
  };

  connectedCallback() {
    if (super.connectedCallback) super.connectedCallback();
    this.addEventListener('toggledPopupColumnHider', this._toggleLegend);

    this._loader._$loaderCount = 1;
    this.refresh = setInterval(() => {
      if (this.refreshTimer) {
        this.getDataLayer(true);
      }
    }, 60000);
    this._firstWWVisit = sessionStorage.getItem('firstWWVisit');
  }

  disconnectedCallback() {
    if (super.disconnectedCallback) super.disconnectedCallback();
    this.removeEventListener('toggledPopupColumnHider', this._toggleLegend);
    clearInterval(this.refresh);
  }

  _toggleLegend(e) {
    const l = this.shadowRoot.querySelector('#overview-legend');
    if (l && e.detail?.visible === true) l.collapsed = true;
  }

  exportTable() {
    const table = getQueryObject(this, 'ki-responsive-table');

    if (table) {
      navigator.clipboard.writeText(table.toCSV(getCsvDelimiter())).then(
        () => {
          getQueryObject(this, '#DownloadSuccessToast').show();
        },
        () => {
          getQueryObject(this, '#DownloadFailureToast').show();
        },
      );
    } else {
      getQueryObject(this, '#DownloadNoTableToast').show();
    }
  }

  get _switcherOptions() {
    return [
      { label: 'Map', value: 'map', icon: 'ki ki-map' },
      { label: 'Table', value: 'table', icon: 'ki ki-table' },
    ].map(tab => ({
      label: this.i18n.t(tab.label),
      value: tab.value,
      icon: tab.icon,
    }));
  }

  // eslint-disable-next-line class-methods-use-this
  get localStorageId() {
    return `wwp-favorites-ids`;
  }

  // Vaadin Router Method that gets called after this component is called
  // eslint-disable-next-line no-unused-vars
  onAfterEnter(location) {
    this.filterContent = '{}';
    const params = getRouteOptionsAndParams(location, ['layerName']);
    this.layerName = params.layerName;
    Object.assign(this, params.options);
  }

  // eslint-disable-next-line class-methods-use-this
  additionalContent() {
    return html``;
  }

  // eslint-disable-next-line class-methods-use-this
  parseData(data) {
    return data;
  }

  enableAutoZoom() {
    this._enableAutoZoom = true;
    setTimeout(() => {
      this._enableAutoZoom = false;
    }, 50);
  }

  render() {
    // eslint-disable-next-line wc/no-self-class
    this.classList.toggle('table', this.mapOrTable === 'table');
    // TODO rename ki-station-layer to ki-maker-layer or value layer ?
    // language=html
    if (this.currentLayer !== this.layerName) {
      this.getDataLayer();
    }
    return (
      this._renderLoader() ||
      html`
        ${this.additionalContent()}

        <ki-searchbox
          placeholder="${this.i18n.t('stationsearch')}"
          .value="${this.searchContent}"
          @search="${e => {
            this.enableAutoZoom();
            this.searchContent = e.detail.value;
          }}"
        >
        </ki-searchbox>
        ${this.showCsvExport && this.mapOrTable === 'table'
          ? this.renderExportButton()
          : html``}
        <ki-wcp-wwp-filter
          .hideFavorite="${this.hideFavoriteButton}"
          .favorites="${this.__favouriteStationIds}"
          .activeFilter="${this.filterContent}"
          .allStations="${this._dataLayer.data}"
          .stations="${this.filteredStations}"
          .filters="${this.quickFilters}"
          @change="${e => {
            this.enableAutoZoom();
            this.filterContent = JSON.stringify(e.detail.value);
          }}"
        >
        </ki-wcp-wwp-filter>
        <ki-switcher
          id="map-table-switcher"
          .options="${this._switcherOptions}"
          .value="${this.mapOrTable}"
          @changed="${e => {
            this.enableAutoZoom();
            this.mapOrTable = e.detail.value;
          }}"
        ></ki-switcher>
        <ki-stack class="slide-left" .selected="${this.mapOrTable}">
          <div class="station-list" key="table">
            ${this.mapOrTable === 'table' ? this.renderTable() : html``}
          </div>
          <div class="map-container" key="map">
            ${this.mapOrTable === 'map' ? this.renderMap() : html``}
          </div>
        </ki-stack>
        <ki-legend
          closeable
          .options="${this.legendOptions}"
          @changed="${this.legendChanged}"
          id="overview-legend"
        ></ki-legend>
        <div class="lastUpdate" title="Version: ${this.version}">
          ${this.mapLastSyncedLabel
            ? this.mapLastSyncedLabel
            : this.i18n.t('lastupdated')}:
          ${dayjs.tz(this._dataLayer.creationDateInMillis).format('L LT')}
          ${this.__getTimezone()}
        </div>
      `
    );
  }

  __getTimezone() {
    if (this.tzFormat) {
      return this.i18n.exists(
        encodeURIComponent(dayjs().tz().format(this.tzFormat)),
      )
        ? this.i18n.t(encodeURIComponent(dayjs().tz().format(this.tzFormat)))
        : dayjs().tz().format(this.tzFormat);
    }
    return '';
  }

  renderExportButton() {
    return html`<ki-icon-btn
        class="ripple"
        id="copyToClipboardButton"
        icon="ki ki-clipboard"
        title="Copy table to clipboard"
        @click="${this.exportTable}"
      ></ki-icon-btn>
      <ui5-toast id="DownloadSuccessToast" class="success-toast"
        >${this.i18n.t('copyTableMessage')}</ui5-toast
      >
      <ui5-toast id="DownloadNoTableToast" class="failure-toast"
        >${this.i18n.t('copyTableNoTableMessage')}</ui5-toast
      >
      <ui5-toast id="DownloadFailureToast" class="failure-toast"
        >${this.i18n.t('copyTableFailureMessage')}</ui5-toast
      >`;
  }

  get filteredStations() {
    const regex = new RegExp(
      `${
        this.strictSearch
          ? `^${this.searchContent.replaceAll('*', '.')}`
          : this.searchContent.replaceAll('*', '.')
      }`,
      'i',
    );
    let filterOptions = JSON.parse(this.filterContent);

    if (filterOptions && this._dataLayer.config.filter) {
      filterOptions = pickBy(filterOptions, (value, key) =>
        this._dataLayer.config.filter.some(
          f => f.field === key || key === 'fav',
        ),
      );
    }

    let searchData = this._dataLayer.data?.filter(
      s =>
        regex.test(s.station_longname || s.station_name) ||
        regex.test(s.station_no),
    );
    if (filterOptions.fav) {
      searchData = searchData.filter(s =>
        this.__favouriteStationIds.includes(s.station_id),
      );
    }
    delete filterOptions.fav;
    return filter(searchData, obj =>
      every(filterOptions, (value, key) => {
        const val = obj[key] && obj[key].toString();
        return Array.isArray(value) ? value.includes(val) : val === value;
      }),
    ).filter(s => {
      const tag = s.__tag;
      if (tag && this._legends.length > 0) {
        return tag.split('|').every(t => this._legends.indexOf(t) !== -1);
      }
      return true;
    });
  }

  get quickFilters() {
    return this._dataLayer.config?.filter || [];
  }

  get popup() {
    return this._dataLayer.config?.popup || 'ww-map-popup';
  }

  get sortedBy(): Array<SortedBy> {
    return this._dataLayer.config?.sortedBy;
  }

  // Presort by
  renderTable() {
    return html`<ki-responsive-table
      .data="${this._initialOrder(this.filteredStations)}"
      idproperty="station_id"
      .layerAlias="${this.layerName}"
      .infoTag="${this.popup}"
      sort="${this.sortedBy
        ? JSON.stringify(this.sortedBy)
        : '[{"field":"station_name", "ascending":true}]'}"
      .columns="${this._dataLayer.config.columns}"
      .tablePopup="${this._dataLayer.config.tablePopup}"
      .formatters="${this.formatters}"
      @row-click="${this.showStation}"
    ></ki-responsive-table>`;
  }

  _initialOrder(filteredStations: Array<object>) {
    // Transform provided dataset from layercfg
    const fields: Array<string> = []; // field name
    const orders: Array<'asc' | 'desc'> = [];
    if (this.sortedBy && Array.isArray(this.sortedBy)) {
      this.sortedBy.forEach(_sort => {
        _sort.ascending ? orders.push('asc') : orders.push('desc');
        _sort.sortBy ? fields.push(_sort.sortBy) : fields.push(_sort.field);
      });
      return orderBy(filteredStations, fields, orders);
    }
    return filteredStations;
  }

  renderMap() {
    return html`<ki-station-map
      id="map"
      .view="${this._view}"
      .layers="${this.mapConfig?.layers || []}"
      persistancetimeout="3600"
      cachePrefix="${this.cachePrefix}"
    >
      ${this._dataLayer.config.popupControl
        ? html`<ki-popup-control></ki-popup-control>`
        : html``}

      <ki-stations-layer
        .displayStationLabelsOnStart="${this.displayStationLabelsOnStart}"
        .displayInLayerControl="${this.displayStationsInLayerControl}"
        .infoTag="${this.popup}"
        .tagMarkers="${this.legendFactory.markers}"
        .layerAlias="${this.layerName}"
        .labelTemplate="${this._dataLayer.config?.labelTemplate}"
        .trendKey=${this._dataLayer.config?.trendKey}
        .tagSelectedMarkers="${this.legendFactory.selectedMarkers}"
        .config="${this._dataLayer.config}"
        .stations="${this.filteredStations}"
        .dynamicMarkerSize="${this._dataLayer.config?.dynamicMarkerSize}"
        @station-click="${this.showStation}"
        @sourceChange="${e => {
          if (
            this.zoomReset ||
            this._enableAutoZoom ||
            this._firstWWVisit !== 'no'
          ) {
            this.stationMap.zoomTo(
              e.detail.extent,
              this._enableAutoZoom ? 500 : 0,
              this.extentBuffer,
            );
            this._firstWWVisit = 'no';
            sessionStorage.setItem('firstWWVisit', 'no');
          }
        }}"
      >
      </ki-stations-layer>
      <ki-layers-control
        .treeList="${this.layersTreeList}"
        .toggleAll="${this.layersToggleAll}"
        .context="${this.layerName}"
        .i18n=${this.i18n}
        .groups="${this.layerGroups}"
        cachePrefix="${this.cachePrefix}"
      ></ki-layers-control>
      <ki-station-map-zoom-control
        style="right:20px;left:auto;"
        label="map zoom"
        .homeExtent="${ViewPort.size === SM
          ? this.homeExtentMobile
          : this.homeExtent}"
      ></ki-station-map-zoom-control>
      <ki-station-map-position-scale-control
        .projections="${this.positionProjections}"
        label="position and scale"
      ></ki-station-map-position-scale-control>
      <ki-station-map-overview-control
        label="overview"
        .layerOptions="${this.mapConfig?.overViewLayer || { layer: [] }}"
      ></ki-station-map-overview-control>
      <!--     <ki-station-map-raster-control></ki-station-map-raster-control> -->
    </ki-station-map>`;
  }

  // eslint-disable-next-line class-methods-use-this
  showStation(e) {
    // TODO provide route-link ,  route.linkTo
    // TODO site_no ?
    const station = e.detail.data;
    const label = station.station_longname || station.station_name;
    if (station.external && station.projectName)
      /** Go to project detail page, see ww-worldmap
       *  station_id ~ project_id
       * */
      window.open(
        `#/overview/${this.layerName}/${station.station_id}`,
        '_self',
      );
    else if (station.external && station.url) {
      window.open(station.url, '_new');
    } else if (station.URLEXTERN) {
      window.open(station.URLEXTERN, '_new');
    } else {
      let navigationPath = `/overview/${this.layerName}/station/${
        station.station_id
      }/${encodeURIComponent(label)}`;

      let detailPaths =
        this.detailsPaths?.filter(path => path.layerName === this.layerName) ??
        [];

      const anyHits = detailPaths.filter(path => {
        if (!path.anyParameters) {
          return false;
        }

        for (const param of path.anyParameters) {
          if (station.ts_values[param]) {
            return true;
          }
        }
        return false;
      });

      const allHits = detailPaths.filter(path => {
        if (!path.parameters) {
          return false;
        }

        for (const param of path.parameters) {
          if (!station.ts_values[param]) {
            return false;
          }
        }
        return true;
      });

      detailPaths = detailPaths.filter(
        path => !path.anyParameters && !path.parameters,
      );

      let detailPath;

      /* eslint-disable prefer-destructuring */
      if (anyHits.length > 0) {
        detailPath = anyHits[0];
      } else if (allHits.length > 0) {
        detailPath = allHits[0];
      } else if (detailPaths.length > 0) {
        detailPath = detailPaths[0];
      }
      /* eslint-enable prefer-destructuring */

      if (detailPath) {
        navigationPath = `${navigationPath}/${detailPath.detailsPath}`;
      }

      navigateTo(`${navigationPath}?mode=${this.mapOrTable}`);
    }
  }

  async getDataLayer(sameLayer: boolean = false) {
    if (!this.mapsLoaded) {
      const mapConfig = await this.api.getMapConfig();
      this.mapConfig = mapConfig;
    }
    if (this.layerName) {
      this.currentLayer = this.layerName;

      let resp;
      try {
        resp = await this.api.getLayer(this.layerName);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(
          `error loading layer "${this.layerName}". Going back to start...`,
        );
        navigateTo('/');
        return;
      }

      if (!resp?.config) {
        // eslint-disable-next-line no-console
        console.warn(
          `layer "${this.layerName}" not found or has no config. Going back to start`,
        );
        navigateTo('/');
        return;
      }

      if (this.zoomReset && sessionStorage) {
        sessionStorage.removeItem(`${this.cachePrefix}-ki-station-map-zoom`);
      }

      resp.data = reproject(resp.data, this.mapConfig);
      // console.time('clientFilter');

      if (this.parameterFilterAttribute) {
        try {
          resp.data.forEach(station => {
            if (station[this.parameterFilterAttribute]) {
              const parFilter = JSON.parse(
                station[this.parameterFilterAttribute],
              );
              if (Array.isArray(parFilter) && parFilter.length !== 0) {
                Object.keys(station.ts_values).forEach(par => {
                  if (!parFilter.includes(par)) {
                    delete station.ts_values[par];
                  }
                });
              }
            }
          });
          resp.data = resp.data.filter(
            item => Object.keys(item.ts_values).length,
          );
        } catch (e) {
          console.error('invalid parameterFilterAttribute');
        }
      }

      resp.data.forEach(item => {
        if (item.ts_value) item.ts_value = parseFloat(item.ts_value);
        if (item.ts_values?.temp?.ts_value)
          item.ts_values.temp.ts_value = parseFloat(
            item.ts_values.temp.ts_value,
          );
      });
      // console.timeEnd('clientFilter');
      const data = new Classifier(resp.config.classification).classify(
        this.parseData(resp.data),
      );
      const tagCount =
        resp.config.classification.tagCount !== false
          ? countBy(data, '__tag')
          : null;
      this._loader._$loaderCount = 0;
      this._dataLayer = {
        config: resp.config,
        data,
        tagCount,
        creationDateInMillis: resp.creationDateInMillis,
      };
      if (!sameLayer) {
        this._legends = [];
      }
      if (!this.mapsLoaded) {
        registerProjections(this.mapConfig);
        this._view = this.mapConfig.view || this._view;
        this.homeExtent = this.mapConfig.homeExtent;
        this.homeExtentMobile =
          this.mapConfig.homeExtentMobile ?? this.mapConfig.homeExtent;
        this.positionProjections = this.mapConfig.positionProjections;
        this.displayStationsInLayerControl =
          this.mapConfig.displayStationsInLayerControl;
        this.mapsLoaded = true;
      }
      this.requestUpdate();
    }
  }

  get legendFactory() {
    return new LegendFactory(this._dataLayer.config.classification);
  }

  get legendOptions() {
    const opts = this.legendFactory.getLegendOptions();
    return [{ ...opts, tagCount: this._dataLayer.tagCount }];
  }

  get formatters() {
    const factory = this.legendFactory;
    return {
      status(val, locale, col) {
        return StatusIcon(factory.pngs[val], factory.labels[val], col);
      },
      info(val, locale, col) {
        // todo move into options.
        return val
          ? html`<div class="cell" style="text-align: center;">
              <ki-icon
                style="color:rgba(255,221,66,1);"
                title="${val}"
                icon="${col.icon || 'ki ki-info-circle'}"
              ></ki-icon>
            </div>`
          : html`<div></div> `;
      },
      trend(val) {
        const trend =
          val !== null && val !== undefined
            ? `transform: rotateZ(${getTrendArrow(val)}deg)`
            : 'display:none;';
        return html`<div class="cell">
          <ki-icon
            style="font-size:1.5em;${trend}"
            icon="ki ki-arrow-right"
          ></ki-icon>
        </div>`;
      },
      sparkline(val, opts, col, item) {
        const vals = opts.options.values
          .map(value => ({
            unit: item?.ts_values[item.mainParameter]?.ts_unitsymbol,
            name: item?.ts_values[item.mainParameter]
              ?.stationparameter_longname,
            value: item[value],
            date: item[value.replace('ts_value', 'timestamp')],
          }))
          .filter(f => f.value);
        return html`<ki-wwp-sparkline .data="${vals}"> </ki-wwp-sparkline>`;
      },
    };
  }

  legendChanged(e) {
    this._legends = e.detail.value;
  }
}
