import { css, html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
// eslint-disable-next-line import/extensions
import { property } from 'lit/decorators/property.js';
import { i18nMixin } from '@kisters/wcp-base/decorators';
import { getCurrentApi } from '@kisters/wiski-web/api';
import { Mix } from '@kisters/wcp-base/common';
import { getRouteOptionsAndParams } from '@kisters/wcp-base/components';
import '@ui5/webcomponents/dist/ComboBox';
import '@ui5/webcomponents/dist/Select';
import '@ui5/webcomponents/dist/Button';
import '@ui5/webcomponents/dist/Panel';
import '@ui5/webcomponents/dist/BusyIndicator';
import { getConfig } from '@kisters/wcp-base/app';
import cgiService from '../services/tceqCgiService';
import nls from '../locales/index';

import {
  fetchStationParams,
  filterStationParams,
  months,
} from '../services/helpers';

@customElement('ki-tceq-monthly-summary')
export default class kiTceqMonthlySummary extends Mix(LitElement, [
  i18nMixin,
  { nls },
]) {
  // language=CSS
  static styles = css`
    :host {
      position: relative;
    }
    .loader-mask {
      background: #fff;
      display: flex;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 9999;
      opacity: 0.9;
    }
    .loader {
      width: 100%;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .form-label {
      margin: 10px 0;
    }
    .disclaimer {
      margin-top: 25px;
      color: gray;
    }
  `;

  @property({ type: Boolean })
  loading = true;

  currentYear = new Date().getFullYear();

  // eslint-disable-next-line class-methods-use-this
  generateYearArray() {
    const yearArray: string[] = [];
    for (let year = this.currentYear; year >= 1996; year -= 1) {
      yearArray.push(year.toString());
    }
    return yearArray;
  }

  years = this.generateYearArray();

  firstUpdated() {
    const config = getConfig();
    this.kiwisUrl = config.kiwisUrl;
    this.qualityCodes = config.tceqQualityCodes;
  }

  @property()
  paramCoverage = {};

  @property()
  parameters = {};

  defaultStats = [
    'monthlyMax',
    'dailyMax',
    'monthlySH',
    'dailySH',
    'monthlyMin',
    'dailyMin',
    'monthlyAvg',
    'dailyAvg',
    'monthlySTD',
    'dailySTD',
    'monthlyCap',
    'dailyCap',
  ];

  @property({
    type: {
      station: String,
      year: String,
      month: String,
      parameters: Array<String>,
    },
  })
  filt: any = {
    year: this.currentYear,
    month: 'January',
    statistics: this.defaultStats,
  };

  @property({ type: Boolean })
  showGraph = false;

  @property({ type: Boolean })
  showReport = false;

  @property({ type: Object })
  api = getCurrentApi();

  async onAfterEnter(location) {
    const params = getRouteOptionsAndParams(this.location, [
      'layerName',
      'stationId',
      'stationLabel',
    ]);
    this.api = getCurrentApi();
    this.stationId = params.stationId;
    Object.assign(this, params.options);
    this.station = await this.api.getStation(this.stationId);
    this.filt = { ...this.filt, station: this.station.station_no };
    const p = await fetchStationParams(
      this.kiwisUrl,
      this.station.station_no,
      '300.HourlyAverage',
    );
    this.parameters = p.parameters;
    this.paramCoverage = p.paramCoverage;
    this.loading = false;
  }

  _handleToggleGraph = () => {
    this.showGraph = !this.showGraph;
  };

  _handleMonthSelect(evt) {
    this.filt = { ...this.filt, month: evt.detail.selectedOption.value };
  }

  _handleYearSelect(evt) {
    this.filt = { ...this.filt, year: evt.detail.selectedOption.value };
  }

  _handleCbChange(evt) {
    this.parameters = {
      ...this.parameters,
      [evt.target.id]: {
        ...this.parameters[evt.target.id],
        selected: evt.target.checked,
      },
    };
  }

  _handleCbSelectAll(evt) {
    // TODO: check
    Object.values(this.parameters).forEach(o => {
      o.selected = evt.target.checked;
    });
    this.parameters = { ...this.parameters };
  }

  _handleStatsChange(evt) {
    const statistics = Object.keys(evt.detail).filter(
      k => evt.detail[k] === true,
    );
    this.filt = { ...this.filt, statistics };
  }

  _resetFilter() {
    // TODO: check
    this.filt = {
      station: this.station.station_no,
      month: 'January',
      year: this.currentYear,
      statistics: this.defaultStats,
    };
    Object.values(this.parameters).forEach((o: any) => {
      o.selected = false;
    });
  }

  // eslint-disable-next-line class-methods-use-this
  _formatData(data) {
    console.log(data);
    return data; // TODO: loop over params in data when rendering
  }

  async _fetchReport() {
    const _parameters: String[] = [];
    Object.values(this.parameters).forEach((element: any) => {
      if (element.selected) {
        _parameters.push(element.value);
      }
    });
    if (!_parameters.length) {
      window.alert('No parameters selected');
    } else {
      this.loading = true;
      this.filt = { ...this.filt, parameters: _parameters };
      try {
        const data = await cgiService.cgiScript(
          'water_monthly_yearly_summary.py',
          this.filt,
        );
        this.data = this._formatData(data);
      } catch (error) {
        console.error(error);
        this.data = { table: [], graph: [] };
      }
      this.loading = false;
      this.showReport = true;
    }
    // console.log(this.filt);
    // const res = await fetch('water_monthly_yearly_summary.json'); // TODO: temp
    // const data = await res.json();
    // this.data = data;
    // this.loading = false;
    // this.showReport = true;
  }

  triggerDownload(param) {
    const elementChild = this.shadowRoot.getElementById(`${param}-table`);
    elementChild.handleDownload([
      `${this.filt.month} ${this.filt.year} Monthly Summary for ${
        this.station.station_name
      } | ${this.parameters[param]?.label ?? param}`,
    ]);
  }

  renderParamList() {
    const filteredParams = filterStationParams(
      this.parameters,
      this.paramCoverage,
      this.filt,
    );
    if (Object.keys(filteredParams).length) {
      return html`<div style="display: flex; flex-direction: column; align-items: flex-start; max-height: 500px; flex-wrap: wrap;">
      <div class="form-label">Select parameter(s):</div>
       <ui5-checkbox
          id="select-all"
          text="Select all"
          @change=${this._handleCbSelectAll}
        ></ui5-checkbox>
        ${Object.values(filteredParams).map(
          (item: any) =>
            html`<div style="display: flex; align-items: center">
              <ui5-checkbox
                id=${item.value}
                ?checked=${this.parameters[item.value].selected}
                @change=${this._handleCbChange}
              ></ui5-checkbox
              ><ki-tceq-glossary-link
                .link="${item.longname}"
                .label="${item.longname}"
              ></ki-tceq-glossary-link>
              &nbsp;
              <ki-tceq-glossary-link
                link="POC"
                .label="${item.poc}"
              ></ki-tceq-glossary-link>
            </div>`,
        )}
        <ki-tceq-statistics-selector @change="${
          this._handleStatsChange
        }" mode="Monthly"></ki-tceq-statistics-selector>
      </div>
      
      <ui5-button @click="${this._fetchReport}"
        >Generate Report</ui5-button
      >
    </div>`;
    }
    if (
      (this.filt?.start_date && this.filt?.end_date) ||
      (this.filt?.month && this.filt?.year)
    )
      return html`<div style="margin-top: 10px">
        <i>No parameters found</i>
      </div>`;
    return '';
  }

  render() {
    return html`
    ${
      this.loading
        ? html`<div class="loader-mask">
            <ui5-busy-indicator
              class="loader"
              size="Medium"
              active
            ></ui5-busy-indicator>
          </div>`
        : ''
    }
    
    <div style="margin: 20px">
         ${
           !this.showReport
             ? html`<h2>Monthly Summary Report</h2>
                 <div>
                   <div class="form-label">Select a month:</div>
                   <ui5-select @change="${this._handleMonthSelect}">
                     ${months.map(
                       month =>
                         html`<ui5-option value="${month}"
                           >${month}</ui5-option
                         >`,
                     )}
                   </ui5-select>
                   <ui5-select @change="${this._handleYearSelect}">
                     ${this.years.map(
                       year => html`
                         <ui5-option value="${year}"> ${year} </ui5-option>
                       `,
                     )}
                   </ui5-select>
                   ${this.renderParamList()}
                 </div>`
             : html`<ui5-button
                   @click="${() => {
                     this.showReport = false;
                     this.showGraph = false;
                     this._resetFilter();
                   }}"
                   >New Report</ui5-button
                 >
                 ${Object.keys(this.data).length
                   ? Object.keys(this.data).map(
                       param => html`<ui5-button
                           style="position: absolute; right: 45px; z-index: 999; margin: 4px"
                           @click="${() => this.triggerDownload(param)}"
                           ><ki-icon
                             title="Download"
                             icon="ki ki-arrow-alt-to-bottom"
                           ></ki-icon></ui5-button
                         ><ui5-panel
                           width="100%"
                           accessible-role="Complementary"
                           header-text=${`${this.filt.month} ${
                             this.filt.year
                           } Monthly Summary for ${
                             this.station.station_name
                           } | ${
                             param.includes('Statistics')
                               ? param
                               : this.parameters[param]?.label
                           }`}
                           class="full-width"
                         >
                           <div style="height: 40vh;  width: fit-content;">
                             <ki-tceq-table
                               .id="${`${param}-table`}"
                               .minWidth="${'1400px'}"
                               .headerPadding="${'5px 0px 5px 10px'}"
                               .data="${this.data[param]}"
                               .qualityCodes="${this.qualityCodes}"
                             ></ki-tceq-table>
                           </div>
                         </ui5-panel> `,
                     )
                   : html`<div
                       style="height: 100%;display:flex;justify-content:center;align-items:center;"
                     >
                       <div>No data found</div>
                     </div>`}`
         }
         ${
           this.showReport
             ? html`<div style="padding: 10px">
                 Green underline indicates validated data.
               </div>`
             : ''
         }
         <div class="disclaimer">
          <b>PLEASE NOTE:</b> This data has not been verified by the TCEQ and may change. This is the most current data, but it is not official until it has been certified by our technical staff.
         </div>
      </div>
    </div>`;
  }

  // eslint-disable-next-line class-methods-use-this, no-empty-function
  // async fetchData() {
  //   const res = await fetch('daily_summary.json');
  //   this.data = await res.json();
  // }
}
