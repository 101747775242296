import { css, html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import { property } from 'lit/decorators/property.js';

@customElement('ki-tceq-glossary-link')
export default class kiTceqGlossaryLink extends LitElement {
  static styles = css`
    .glossary-link {
      color: var(--_ui5_checkbox_label_color);
      font-size: var(--sapFontSize);
      cursor: help;
    }
  `;

  @property()
  link;

  @property()
  label;

  render() {
    return html`<a
      class="glossary-link"
      href="html/glossary.html#${this.link}"
      target="_blank"
    >
      ${this.label}
    </a>`;
  }
}
