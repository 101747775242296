import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { Mix } from '@kisters/wcp-base/common';
import { i18nMixin } from '@kisters/wcp-base/decorators';
import dayjs from '@kisters/wcp-base/common/dayjsext';
import Highcharts from 'highcharts/highstock';
import { orderBy } from 'lodash-es';
import hclocales from '@kisters/wcp-water/common/highcharts-locales.js';
import { until } from 'lit/directives/until.js';
import nls from '../../locales/index';
import { getCurrentApi } from '../../api/API';

// eslint-disable-next-line import/extensions

@customElement('ww-map-popup-base')
export default class WwMapPopup extends Mix(LitElement, [i18nMixin, { nls }]) {
  static styles = css`
    :host {
      display: block;
      z-index: 100;
      max-width: 460px;
    }
    .station-name {
      padding-right: 5px;
      padding-left: 10px;
      padding-top: 10px;
      font-size: 1.2em;
    }

    hr {
      margin: 5px 0;
    }
    .table {
      display: table;
      line-height: 1.5em;
    }
    .row {
      display: table-row;
    }

    .hidden {
      display: none !important;
    }

    .highlight {
      font-weight: bold;
    }

    .cell {
      display: table-cell;
      padding: 0 10px;
      min-width: 50px;
      color: gray;
    }

    .label {
      color: #4a4a49;
    }

    .highcharts-label {
      display: none;
    }

    #chartcontainer {
      border-top: 1px dotted lightgray;
      height: 200px;
      min-width: 360px;
      width: 100%;
    }

    :host([flexSize]) #chartcontainer {
      width: auto;
      flex: 1;
      height: auto;
    }

    :host([flexSize]) {
      max-width: initial;
    }

    .remark {
      padding-left: 10px;
      color: red;
      max-width: 100%;
    }
  `;

  @property({ type: Boolean })
  flexSize = false;

  @property({ type: Array })
  stations: Array<object> = [];

  /* Additional comment also displayed in left Menu at stationDetils
   * Config: app -> remarkAttr
   */
  @property({ type: String })
  stationRemark;

  constructor() {
    super();
    if (hclocales[this.i18n.language.substring(0, 2)]) {
      Highcharts.setOptions({
        lang: hclocales[this.i18n.language.substring(0, 2)],
      });
    }
    this.stations = [];
    this.api = getCurrentApi();
    this.formatter = new Intl.NumberFormat(this.i18n.language, {});
  }

  valFormatter(val) {
    return val === 0 ? val : this.formatter.format(val);
  }

  /* eslint-disable-next-line class-methods-use-this */
  _getRow(station, mainParameter) {
    const isMain =
      (mainParameter && mainParameter === station.stationparameter_no) ||
      mainParameter === station.stationparameter_name;
    const parameter =
      station.stationparameter_longname || station.stationparameter_name;
    const value =
      station.ts_value != null
        ? `${this.valFormatter(station.ts_value)} ${station.ts_unitsymbol}`
        : '-';
    const timestamp = station.timestamp
      ? dayjs(station.timestamp).tz().format('L LT')
      : '-';
    return html`<div class="row ${isMain ? 'highlight' : ''}">
      <div class="cell label">${parameter}</div>
      <div class="cell"><b>${value}</b> (${timestamp})</div>
    </div>`;
  }

  // eslint-disable-next-line class-methods-use-this
  getGraph(station) {
    // override for graph
    console.log(station);
    return html``;
  }

  _stationTmpl(stations, graph = true) {
    const station = stations[0];
    const river = station.WTO_OBJECT ? `(${station.WTO_OBJECT})` : '';

    if (station && this.config?.remarkAttr)
      this.stationRemark = station[this.config?.remarkAttr] || '';

    return html`<div class="station-name">
        ${station.station_no} | ${station.station_name} ${river}
      </div>
      <div class="remark">${this.stationRemark}</div>
      <hr />
      <div class="table">
        ${station.ts_values
          ? orderBy(Object.values(station.ts_values), [
              item => item.stationparameter_name.toLowerCase(),
            ]).map(st => this._getRow(st, station.mainParameter))
          : stations.map(st => this._getRow(st, station.mainParameter))}
      </div>
      ${graph
        ? until(
            this.getGraph(station),
            html`<span style="margin: 10px">...</span>`,
          )
        : ''}`;
  }

  render() {
    return this?.stations?.length
      ? this._stationTmpl(this.stations)
      : html`<div></div>`;
  }
}
