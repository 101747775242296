export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const filterStationParams = (parameters, paramCoverage, filt) => {
  let filtTo;
  let filtFrom;
  if (filt?.end_date && filt?.start_date) {
    filtTo = new Date(filt.end_date);
    filtFrom = new Date(filt.start_date);
  } else if (filt?.year) {
    if (filt?.month) {
      const monthIndex = months.findIndex(
        month => month.toLowerCase() === filt.month.toLowerCase(),
      );
      const numericMonth = monthIndex !== -1 ? monthIndex : 0;
      filtFrom = new Date(filt.year, numericMonth);
      filtTo = new Date(filt.year, numericMonth + 1, 0);
    } else {
      filtFrom = new Date(filt?.year, 0, 1);
      filtTo = new Date(filt?.year, 12, 0);
    }
  }
  filtFrom?.setUTCHours(0);
  filtTo?.setUTCHours(23, 59, 59);
  return Object.fromEntries(
    Object.entries(parameters).filter(([k, v]) => {
      try {
        const to = new Date(paramCoverage[k].to);
        const from = new Date(paramCoverage[k].from);
        from.setUTCHours(0);
        to.setUTCHours(23, 59, 59);
        return (
          (filtTo >= from && filtTo <= to) ||
          (filtFrom >= from && filtFrom <= to)
        );
      } catch (error) {
        console.warn(error);
        return false;
      }
    }),
  );
};

export const formatStationParams = (data: any[]) => {
  const _params = {};
  data.forEach(p => {
    if (p.stationparameter_name !== 'LOGS') {
      _params[p.stationparameter_name] = {
        label: `${
          p.stationparameter_longname
        } (POC: ${p.stationparameter_name.slice(-1)})`,
        value: p.stationparameter_name,
        longname: p.stationparameter_longname,
        poc: `POC: ${p.stationparameter_name.slice(-1)}`,
        selected: false,
      };
    }
  });
  return _params;
};

export async function fetchStationParams(
  kiwisUrl,
  stationNo,
  tsName = '200.Edited',
) {
  const paramCoverage = {};
  try {
    const res = await fetch(
      `${kiwisUrl}?datasource=0&service=kisters&type=queryServices&request=getparameterlist&returnfields=parametertype_id,parametertype_name,parametertype_longname,parametertype_shortunitname,parametertype_unitname,stationparameter_id,stationparameter_name,stationparameter_no,stationparameter_longname&format=objson&station_no=${stationNo}`,
    );
    const data = await res.json();

    const promises = data.map(async p => {
      try {
        const r = await fetch(
          `${kiwisUrl}?datasource=0&service=kisters&type=queryServices&request=getTimeseriesList&dateformat=yyyy-MM-dd&returnfields=stationparameter_name,coverage&format=objson&station_no=${stationNo}&stationparameter_name=${p.stationparameter_name}&ts_name=${tsName}`,
        );
        const d = await r.json();
        if (d[0]) {
          paramCoverage[d[0].stationparameter_name] = {
            from: d[0].from,
            to: d[0].to,
          };
        }
      } catch (error) {
        console.error(error);
      }
    });
    await Promise.all(promises);
    return { parameters: formatStationParams(data), paramCoverage };
  } catch (error) {
    console.error(error);
    return { parameters: {}, paramCoverage: {} };
  }
}

export const sortCols = columns => {
  const sortingOrder = [
    'parameter',
    'day',
    'date',
    'time',
    'timestamp',
    /^\d/,
    /.*/,
  ];

  columns.sort((a, b) => {
    const _a = typeof a === 'string' ? a : a.field;
    const _b = typeof b === 'string' ? b : b.field;
    const aIndex = sortingOrder.findIndex(pattern => {
      if (typeof pattern === 'string') {
        return _a.toLowerCase() === pattern;
      }
      return pattern.test(_a);
    });

    const bIndex = sortingOrder.findIndex(pattern => {
      if (typeof pattern === 'string') {
        return _b.toLowerCase() === pattern;
      }
      return pattern.test(_b);
    });

    return aIndex - bIndex;
  });
};
