import { css, html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
// eslint-disable-next-line import/extensions
import { property } from 'lit/decorators/property.js';
import { i18nMixin } from '@kisters/wcp-base/decorators';
import { getCurrentApi } from '@kisters/wiski-web/api';
import { Mix } from '@kisters/wcp-base/common';
import { getRouteOptionsAndParams } from '@kisters/wcp-base/components';
import '@ui5/webcomponents/dist/ComboBox';
import '@ui5/webcomponents/dist/DatePicker';
import '@ui5/webcomponents/dist/Button';
import '@ui5/webcomponents/dist/Panel';
import '@ui5/webcomponents/dist/BusyIndicator';
import { getConfig } from '@kisters/wcp-base/app';
import cgiService from '../services/tceqCgiService';
import nls from '../locales/index';

@customElement('ki-tceq-hourly')
export default class kiTceqHourly extends Mix(LitElement, [
  i18nMixin,
  { nls },
]) {
  // language=CSS
  static styles = css`
    :host {
      position: relative;
    }
    .loader-mask {
      background: #fff;
      display: flex;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 9999;
      opacity: 0.9;
    }
    .loader {
      width: 100%;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .form-label {
      margin: 10px 0;
    }
    .disclaimer {
      margin-top: 25px;
      color: gray;
    }
  `;

  @property({ type: Boolean })
  loading = true;

  firstUpdated() {
    const config = getConfig();
    this.kiwisUrl = config.kiwisUrl;
    this.qualityCodes = config.tceqQualityCodes;
  }

  @property({
    type: {
      station: String,
      start_date: String,
      end_date: String,
      parameters: Array<String>,
    },
  })
  filt;

  @property({ type: Boolean })
  showGraph = false;

  @property({ type: Boolean })
  showReport = false;

  @property({ type: Object })
  api = getCurrentApi();

  validDate = false;

  async onAfterEnter(location) {
    const params = getRouteOptionsAndParams(this.location, [
      'layerName',
      'stationId',
      'stationLabel',
    ]);
    this.api = getCurrentApi();
    this.stationId = params.stationId;
    Object.assign(this, params.options);
    this.station = await this.api.getStation(this.stationId);
    this.filt = { ...this.filt, station: this.station.station_no };
    console.log(this.station);
    this.loading = false;
  }

  _handleToggleGraph = () => {
    this.showGraph = !this.showGraph;
  };

  _handleDateChange(evt) {
    if (evt.detail.valid) {
      this.filt = { ...this.filt, date: evt.detail.value };
      this.validDate = true;
    } else this.validDate = false;
  }

  _handleCbChange(evt) {
    this.parameters = {
      ...this.parameters,
      [evt.target.id]: {
        ...this.parameters[evt.target.id],
        selected: evt.target.checked,
      },
    };
  }

  _handleCbSelectAll(evt) {
    // TODO: check
    Object.values(this.parameters).forEach(o => {
      o.selected = evt.target.checked;
    });
    this.parameters = { ...this.parameters };
  }

  _resetFilter() {
    // TODO: check
    this.filt = { station: this.station.station_no };
    this.validDate = false;
    Object.values(this.parameters).forEach((o: any) => {
      o.selected = false;
    });
  }

  // eslint-disable-next-line class-methods-use-this
  _formatData(data) {
    let noData = true;
    const table = data.table
      .filter(row => row['00:00'])
      .map(row => {
        let _row;
        if (Object.keys(row).length <= 2) {
          _row = {
            parameter: row.param_name,
            POC: row.param_no.slice(-1),
          };
          for (let hour = 0; hour <= 23; hour += 1) {
            const time = `${hour.toString().padStart(2, '0')}:00`;
            _row[time] = { value: null };
          }
        } else {
          _row = {
            parameter: row.param_name,
            POC: row.param_no.slice(-1),
            ...row,
          };
          noData = false;
        }
        delete _row.param_name;
        delete _row.param_no;
        return _row;
      });
    const _data = {
      graph: data.graph.filter(row => row.data.length),
      table: noData ? [] : table,
    };
    return _data;
  }

  async _fetchReport() {
    if (!this.validDate) {
      window.alert('Invalid date');
    } else {
      this.loading = true;
      try {
        const data = await cgiService.cgiScript(
          'water_daily_summary.py',
          this.filt,
        );
        this.data = this._formatData(data);
      } catch (error) {
        console.error(error);
        this.data = { table: [], graph: [] };
      }

      this.loading = false;
      this.showReport = true;
    }
    // this.loading = true;
    // const res = await fetch('hourly.json');
    // this.data = await res.json();
    // console.log(this.data);
    // this.loading = false;
    // this.showReport = true;
  }

  triggerDownload(id) {
    const elementChild = this.shadowRoot.getElementById(id);
    elementChild.handleDownload([
      `Current Measurements for ${this.station.station_name}`,
    ]);
  }

  render() {
    return html`
    ${
      this.loading
        ? html`<div class="loader-mask">
            <ui5-busy-indicator
              class="loader"
              size="Medium"
              active
            ></ui5-busy-indicator>
          </div>`
        : ''
    }
    
    <div style="margin: 20px">
      <h2>Current Measurements</h2>
        <div style="max-width: 1000px; margin-bottom: 10px">
          Although this is the most current data, it is not considered
          official until it has been certified by the technical staff.
          This information is updated hourly.
          <br /><br />
          This web page provides the most current hourly averaged data
          available. Our convention for time-tagging data is the
          beginning of each hour. For example, values shown for the
          noon hour are based on measurements taken from noon to 1:00
          p.m. The noon average will not be calculated until after 1:00
          p.m. The noon average will then be available on our external
          server from 1:15 p.m. to 1:30 p.m. This results in an
          apparent one-hour time lag in the data. We also present our
          data in Local Standard Time for each measuring site. For most
          of Texas this is Central Standard Time. During Daylight
          Savings, this introduces another apparent one-hour time lag
          in the data.
        </div>
         ${
           !this.showReport
             ? html` <div>
                 <div class="form-label">Select a date:</div>
                 <ui5-date-picker
                   id="date-picker"
                   format-pattern="MM/dd/yyyy"
                   placeholder="mm/dd/yyyy"
                   @change="${this._handleDateChange}"
                 ></ui5-date-picker>
                 <ui5-button @click="${this._fetchReport}"
                   >Generate Report</ui5-button
                 >
               </div>`
             : html`<ui5-button @click="${this._handleToggleGraph}">
                   ${this.showGraph ? 'Hide' : 'Show'} Graph
                 </ui5-button>
                 <ui5-button
                   @click="${() => {
                     this.showReport = false;
                     this.showGraph = false;
                     this._resetFilter();
                   }}"
                   >New Report</ui5-button
                 >
                 <div>
                   ${this.showGraph
                     ? html`<ki-tceq-graph
                         .station=${this.station}
                         .data=${this.data}
                         .title="${'Current Measurements'}"
                       ></ki-tceq-graph>`
                     : ''}
                 </div>
                 <ui5-button
                   style="position: absolute; right: 45px; z-index: 999; margin: 4px"
                   @click="${() => this.triggerDownload('table')}"
                   ><ki-icon
                     title="Download"
                     icon="ki ki-arrow-alt-to-bottom"
                   ></ki-icon
                 ></ui5-button>
                 <ui5-panel
                   width="100%"
                   accessible-role="Complementary"
                   header-text=${`Current Measurements for ${this.station.station_name}`}
                   class="full-width"
                 >
                   <div style=" width: fit-content;">
                     <ki-tceq-table
                       id="table"
                       .minWidth="${'1400px'}"
                       .headerPadding="${'5px 0px 5px 10px'}"
                       .data="${this.data?.table}"
                       .qualityCodes="${this.qualityCodes}"
                     ></ki-tceq-table>
                   </div>
                   <div style="padding: 10px">
                     Green underline indicates validated data. Maximum values
                     for each parameter are bold within the table. Minimum
                     values are bold italic.
                   </div>
                 </ui5-panel>`
         }
         <div class="disclaimer">
          <b>PLEASE NOTE:</b> This data has not been verified by the TCEQ and may change. This is the most current data, but it is not official until it has been certified by our technical staff.
         </div>
      </div>
    </div>`;
  }
}
