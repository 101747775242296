/* eslint-disable max-classes-per-file */
import { LoaderMixin, Mix } from '@kisters/wcp-base/common';
import { html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { i18nMixin, responsiveMixin } from '@kisters/wcp-base/decorators';
import WwpStationInfo from '@kisters/wiski-web/views/wwp-station-detail/wwp-station-info';
import '@ui5/webcomponents/dist/Carousel.js';
import nls from '@kisters/wcp-water/locales';

@customElement('ki-tceq-station-info')
export default class KiTceqStationInfo extends Mix(
  WwpStationInfo,
  LoaderMixin,
  responsiveMixin,
  [i18nMixin, { nls }],
) {
  getSlider() {
    // eslint-disable-next-line lit-a11y/alt-text
    return html`<ui5-carousel id="gallery" arrows-placement="Navigation" cyclic>
      ${this.images?.map(
        i =>
          html`<div>
            <img alt="station" src="${i}" />
            <div style="text-align: center; padding: 15px;">
              ${this._getImgLabel(i)}
            </div>
          </div>`,
      )}
    </ui5-carousel>`;
  }

  // eslint-disable-next-line class-methods-use-this
  _getImgLabel(filename) {
    const key = filename.split('.jpg')[0].split('_')[1];
    const map = {
      a: 'Aerial map view',
      b: 'Aerial map view zoomed out',
      d: 'Downstream',
      l: 'Left bank',
      o: 'Overall site view',
      r: 'Right bank',
      tn: 'Thumbnail',
      u: 'Upstream',
      n: 'North',
      s: 'South',
      e: 'East',
      w: 'West',
      ne: 'Northeast',
      nw: 'Northwest',
      se: 'Southeast',
      sw: 'Southwest',
    };
    return map[key];
  }
}
