import { customElement } from 'lit/decorators.js';
import WwpStationDetail from '@kisters/wiski-web/views/wwp-station-detail/wwp-station-detail';
import { each, findIndex, find, some, concat, flatten } from 'lodash-es';

@customElement('wwp-tceq-station-detail')
export default class WwpTceqStationDetail1 extends WwpStationDetail{
  _menuFilter(menu) {    
    const station = this._station;
    return menu.filter(menuItem => {
      if (typeof menuItem.filter === 'string') {
        return station.timeseries.some(ts =>
          menuItem.filter ? menuItem.filter === ts.station_parameter : true,
        );
      }

      // Filter array is OR - logic (one criterium must match to pass!)
      if (Array.isArray(menuItem.filter)) {
        let ret = true;
        for (const [key, value] of Object.entries(menuItem.filter)) {
          ret =
            (ret && station[key] === value) ||
            (ret && station[key] && value === '*');
        }
        return menuItem.filter.some(filter =>
          station.timeseries.some(ts =>
            filter ? filter === ts.station_parameter : true,
          ),
        );
      }

      // Filter object is AND - logic (all criterias must match to pass!)
      if (typeof menuItem.filter === 'object' && menuItem.filter !== null) {
        let ret = true;
        each(menuItem.filter, (val, key) => {
          if (key === 'view') {
            ret = this._mainRoute.includes(val);
          } else if (key === 'parameter') {
            ret =
              ret &&
              val.every(item =>
                station._links.some(link => link.station_parameter === item),
              );
          } else if (key === 'anyparameter') {
            ret =
              ret &&
              val.some(item =>
                station._links.some(link => link.station_parameter === item),
              );
          } else if (key === 'href') {
            ret =
              ret &&
              val.some(item =>
                station._links.some(link => link.href.includes(item)),
              );
          } else if (Array.isArray(val)) {
            ret = ret && val.some(cond => station[key] === cond);
          } else if (val.charAt && val.charAt(0) === '!') {
            ret = ret && station[key] && station[key] !== val.substring(1);
          } else {
            ret =
              (ret && station[key] && val === '*') ||
              (ret && station[key] === val) ||
              station._links.some(item => item[key] === val);
          }
        });
        return ret;
      }
      return true;
    });
  }
}
