import { css, html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
// eslint-disable-next-line import/extensions
import { property } from 'lit/decorators/property.js';
import { i18nMixin } from '@kisters/wcp-base/decorators';
import { getCurrentApi } from '@kisters/wiski-web/api';
import { Mix } from '@kisters/wcp-base/common';
import { getRouteOptionsAndParams } from '@kisters/wcp-base/components';
import '@ui5/webcomponents/dist/ComboBox';
import '@ui5/webcomponents/dist/DateRangePicker';
import '@ui5/webcomponents/dist/Button';
import '@ui5/webcomponents/dist/Panel';
import '@ui5/webcomponents/dist/BusyIndicator';
import { getConfig } from '@kisters/wcp-base/app';
import cgiService from '../services/tceqCgiService';
import nls from '../locales/index';
import { fetchStationParams, filterStationParams } from '../services/helpers';

@customElement('ki-tceq-daily-summary')
export default class kiTceqDailySummary extends Mix(LitElement, [
  i18nMixin,
  { nls },
]) {
  // language=CSS
  static styles = css`
    :host {
      position: relative;
    }
    .loader-mask {
      background: #fff;
      display: flex;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 9999;
      opacity: 0.9;
    }
    .loader {
      width: 100%;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .form-label {
      margin: 10px 0;
    }
    .disclaimer {
      margin-top: 25px;
      color: gray;
    }
  `;

  @property({ type: Boolean })
  loading = true;

  firstUpdated() {
    const config = getConfig();
    this.kiwisUrl = config.kiwisUrl;
    this.qualityCodes = config.tceqQualityCodes;
  }

  @property()
  paramCoverage = {};

  @property()
  parameters = {};

  @property({
    type: {
      station: String,
      start_date: String,
      end_date: String,
      parameters: Array<String>,
    },
  })
  filt;

  @property({ type: Boolean })
  showGraph = false;

  @property({ type: Boolean })
  showReport = false;

  @property({ type: Object })
  api = getCurrentApi();

  validDate = false;

  errorMsg = '';

  async onAfterEnter(location) {
    const params = getRouteOptionsAndParams(this.location, [
      'layerName',
      'stationId',
      'stationLabel',
    ]);
    this.api = getCurrentApi();
    this.stationId = params.stationId;
    Object.assign(this, params.options);
    this.station = await this.api.getStation(this.stationId);
    this.filt = { ...this.filt, station: this.station.station_no };
    const p = await fetchStationParams(this.kiwisUrl, this.station.station_no);
    this.parameters = p.parameters;
    this.paramCoverage = p.paramCoverage;
    this.loading = false;
  }

  _handleToggleGraph = () => {
    this.showGraph = !this.showGraph;
  };

  _checkDateRange = (startDate, endDate) => {
    const oneDay = 24 * 60 * 60 * 1000;
    const start = new Date(startDate);
    const end = new Date(endDate);
    const diffDays = (end - start) / oneDay;
    if (diffDays <= 60) {
      return true;
    }
    this.errorMsg = 'Exceeded maximum allowed date range (60 days)';
    return false;
  };

  _handleDateChange(evt) {
    this.errorMsg = '';
    const [start, end] = evt.detail.value.split(' - ');
    if (evt.detail.valid && this._checkDateRange(start, end)) {
      this.filt = { ...this.filt, start_date: start, end_date: end };
      this.validDate = true;
    } else this.validDate = false;
  }

  _handleCbChange(evt) {
    this.parameters = {
      ...this.parameters,
      [evt.target.id]: {
        ...this.parameters[evt.target.id],
        selected: evt.target.checked,
      },
    };
  }

  _handleCbSelectAll(evt) {
    // TODO: check
    Object.values(this.parameters).forEach(o => {
      o.selected = evt.target.checked;
    });
    this.parameters = { ...this.parameters };
  }

  _resetFilter() {
    // TODO: check
    this.filt = { station: this.station.station_no };
    this.validDate = false;
    Object.values(this.parameters).forEach((o: any) => {
      o.selected = false;
    });
  }

  // eslint-disable-next-line class-methods-use-this
  _formatData(data) {
    const _data: any = {};
    _data['15min'] = data['15min']?.map(d => {
      const res = { Date: d.date, Time: d.time };
      d.data.forEach(param => {
        res[`${param.param_name}POC:${param.param_no.slice(-1)}`] = param;
      });
      return res;
    });
    _data.daily = data.daily?.map(d => {
      const res = { Date: d.date };
      d.data.forEach(param => {
        res[`${param.param_name}POC:${param.param_no.slice(-1)}`] = param;
      });
      return res;
    });
    _data.graph = data.graph;
    return _data;
  }

  async _fetchReport() {
    const _parameters: String[] = [];
    Object.values(this.parameters).forEach(element => {
      if (element.selected) {
        _parameters.push(element.value);
      }
    });
    if (!this.validDate) {
      window.alert(this.errorMsg ? this.errorMsg : 'Invalid date');
    } else if (!_parameters.length) {
      window.alert('No parameters selected');
    } else {
      this.loading = true;
      this.filt = { ...this.filt, parameters: _parameters };
      try {
        const data = await cgiService.cgiScript('dailywater.py', this.filt);
        this.data = this._formatData(data);
      } catch (error) {
        console.error(error);
        this.data = { table: [], graph: [] };
      }

      this.loading = false;
      this.showReport = true;
    }
  }

  triggerDownload(id) {
    const elementChild = this.shadowRoot.getElementById(id);
    elementChild.handleDownload([
      `Data Summary for ${this.station.station_name}`,
    ]);
  }

  renderParamList() {
    const filteredParams = filterStationParams(
      this.parameters,
      this.paramCoverage,
      this.filt,
    );
    if (Object.keys(filteredParams).length) {
      return html`<div class="form-label">Select parameter(s):</div>
        <div
        style="display: flex; flex-direction: column; align-items: flex-start; max-height: 500px; flex-wrap: wrap;"
        >
        <ui5-checkbox
          id="select-all"
          text="Select all"
          @change=${this._handleCbSelectAll}
        ></ui5-checkbox>
        ${Object.values(filteredParams).map(
          (item: any) =>
            html`<div style="display: flex; align-items: center">
              <ui5-checkbox
                id=${item.value}
                ?checked=${this.parameters[item.value].selected}
                @change=${this._handleCbChange}
              ></ui5-checkbox
              ><ki-tceq-glossary-link
                .link="${item.longname}"
                .label="${item.longname}"
              ></ki-tceq-glossary-link>
              &nbsp;
              <ki-tceq-glossary-link
                link="POC"
                .label="${item.poc}"
              ></ki-tceq-glossary-link>
            </div>`,
        )}
        </div>
        <ui5-button @click="${this._fetchReport}"
        >Generate Report</ui5-button
        >
        </div>`;
    }
    if (this.filt?.start_date && this.filt?.end_date)
      return html`<div style="margin-top: 10px">
        <i>No parameters found</i>
      </div>`;
    return '';
  }

  render() {
    return html`
    ${
      this.loading
        ? html`<div class="loader-mask">
            <ui5-busy-indicator
              class="loader"
              size="Medium"
              active
            ></ui5-busy-indicator>
          </div>`
        : ''
    }
    
    <div style="margin: 20px">
         ${
           !this.showReport
             ? html`<h2>Daily Summary Report</h2>
                 <div>
                   <div class="form-label">Select a date range:</div>
                   <ui5-daterange-picker
                     id="daterange-picker"
                     format-pattern="MM/dd/yyyy"
                     delimiter="-"
                     placeholder="mm/dd/yyyy - mm/dd/yyyy"
                     @change="${this._handleDateChange}"
                   ></ui5-daterange-picker>
                   ${this.renderParamList()}
                 </div>`
             : html`<ui5-button @click="${this._handleToggleGraph}">
                   ${this.showGraph ? 'Hide' : 'Show'} Graph
                 </ui5-button>
                 <ui5-button
                   @click="${() => {
                     this.showReport = false;
                     this.showGraph = false;
                     this._resetFilter();
                   }}"
                   >New Report</ui5-button
                 >
                 <div>
                   ${this.showGraph
                     ? html`<ki-tceq-graph
                         .station=${this.station}
                         .data=${this.data}
                         .title="${'Daily Summary'}"
                       ></ki-tceq-graph>`
                     : ''}
                 </div>
                 <ui5-button
                   style="position: absolute; right: 45px; z-index: 999; margin: 4px"
                   @click="${() => this.triggerDownload('15min-table')}"
                   ><ki-icon
                     title="Download"
                     icon="ki ki-arrow-alt-to-bottom"
                   ></ki-icon
                 ></ui5-button>
                 <ui5-panel
                   width="100%"
                   accessible-role="Complementary"
                   header-text=${`Data Summary for ${this.station.station_name}`}
                   class="full-width"
                 >
                   <div style="height: 40vh; width: fit-content;">
                     <ki-tceq-table
                       id="15min-table"
                       .data="${this.data?.['15min']}"
                       .qualityCodes="${this.qualityCodes}"
                     ></ki-tceq-table>
                   </div>
                   <div style="padding: 10px">
                     Green underline indicates validated data. Maximum values
                     for each parameter are bold within the table. Minimum
                     values are bold italic.
                   </div>
                 </ui5-panel>
                 <ui5-button
                   style="position: absolute; right: 45px; z-index: 999; margin: 4px"
                   @click="${() => this.triggerDownload('daily-table')}"
                   ><ki-icon
                     title="Download"
                     icon="ki ki-arrow-alt-to-bottom"
                   ></ki-icon
                 ></ui5-button>
                 <ui5-panel
                   width="100%"
                   accessible-role="Complementary"
                   header-text=${`Daily Average for ${this.station.station_name}`}
                   class="full-width"
                 >
                   <div style="height: 40vh; width: fit-content;">
                     <ki-tceq-table
                       id="daily-table"
                       ?nested=${true}
                       .data="${this.data?.daily}"
                       .qualityCodes="${this.qualityCodes}"
                     ></ki-tceq-table>
                   </div>
                 </ui5-panel>`
         }
         <div class="disclaimer">
          <b>PLEASE NOTE:</b> This data has not been verified by the TCEQ and may change. This is the most current data, but it is not official until it has been certified by our technical staff.
         </div>
      </div>
    </div>`;
  }

  // eslint-disable-next-line class-methods-use-this, no-empty-function
  // async fetchData() {
  //   const res = await fetch('daily_summary.json');
  //   this.data = await res.json();
  // }
}
