import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
// eslint-disable-next-line import/extensions
import { i18nMixin } from '@kisters/wcp-base/decorators';
import { Mix } from '@kisters/wcp-base/common';
import '@ui5/webcomponents/dist/ComboBox';
import '@ui5/webcomponents/dist/Select';
import '@ui5/webcomponents/dist/Button';
import '@ui5/webcomponents/dist/Panel';
import '@ui5/webcomponents/dist/BusyIndicator';
import '@ui5/webcomponents/dist/RadioButton';
import nls from '../locales/index';

@customElement('ki-tceq-statistics-selector')
export default class kiTceqStatisticsSelector extends Mix(LitElement, [
  i18nMixin,
  { nls },
]) {
  // language=CSS
  static styles = css`
    .form-label {
      margin: 10px 0;
    }
    .checkbox {
      color: var(--_ui5_checkbox_label_color);
      font-size: var(--sapFontSize);
      display: flex;
      align-items: center;
    }
  `;

  @property()
  mode = 'Yearly';

  @property()
  statistics;

  firstUpdated() {
    this.statistics =
      this.mode === 'Yearly'
        ? {
            yearlyMax: true,
            dailyMax: true,
            yearlySH: true,
            dailySH: true,
            yearlyMin: true,
            dailyMin: true,
            yearlyAvg: true,
            dailyAvg: true,
            yearlySTD: true,
            dailySTD: true,
            yearlyCap: true,
            dailyCap: true,
          }
        : {
            monthlyMax: true,
            dailyMax: true,
            monthlySH: true,
            dailySH: true,
            monthlyMin: true,
            dailyMin: true,
            monthlyAvg: true,
            dailyAvg: true,
            monthlySTD: true,
            dailySTD: true,
            monthlyCap: true,
            dailyCap: true,
          };
  }

  _handleStatisticsChange(evt) {
    this.statistics = {
      ...this.statistics,
      [evt.target.id]: evt.target.checked,
    };

    const event = new CustomEvent('change', {
      bubbles: true,
      composed: true,
      detail: this.statistics,
    });

    this.dispatchEvent(event);
  }

  render() {
    return html`<div class="form-label">Select statistics:</div>
      <table class="statistics-table">
        <tr>
          <td>
            <ki-tceq-glossary-link
              link="Max"
              label="Maximum Value"
            ></ki-tceq-glossary-link>
          </td>
          <td>
            <div class="checkbox">
              <ui5-checkbox
                id="dailyMax"
                checked
                @change=${this._handleStatisticsChange}
              ></ui5-checkbox>
              Daily
            </div>
          </td>
          <td>
            <div class="checkbox">
              <ui5-checkbox
                .id=${this.mode === 'Yearly' ? 'yearlyMax' : 'monthlyMax'}
                checked
                @change=${this._handleStatisticsChange}
              ></ui5-checkbox>
              ${this.mode}
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <ki-tceq-glossary-link
              link="SH"
              label="Second Highest Value"
            ></ki-tceq-glossary-link>
          </td>
          <td>
            <div class="checkbox">
              <ui5-checkbox
                id="dailySH"
                checked
                @change=${this._handleStatisticsChange}
              ></ui5-checkbox>
              Daily
            </div>
          </td>
          <td>
            <div class="checkbox">
              <ui5-checkbox
                .id=${this.mode === 'Yearly' ? 'yearlySH' : 'monthlySH'}
                checked
                @change=${this._handleStatisticsChange}
              ></ui5-checkbox>
              ${this.mode}
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <ki-tceq-glossary-link
              link="Min"
              label="Minimum Value"
            ></ki-tceq-glossary-link>
          </td>
          <td>
            <div class="checkbox">
              <ui5-checkbox
                id="dailyMin"
                checked
                @change=${this._handleStatisticsChange}
              ></ui5-checkbox>
              Daily
            </div>
          </td>
          <td>
            <div class="checkbox">
              <ui5-checkbox
                .id=${this.mode === 'Yearly' ? 'yearlyMin' : 'monthlyMin'}
                checked
                @change=${this._handleStatisticsChange}
              ></ui5-checkbox>
              ${this.mode}
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <ki-tceq-glossary-link
              link="Avg"
              label="Average Value"
            ></ki-tceq-glossary-link
            ></td>
          </td>

          <td>
            <div class="checkbox">
              <ui5-checkbox
                id="dailyAvg"
                checked
                @change=${this._handleStatisticsChange}
              ></ui5-checkbox>
              Daily
            </div>
          </td>
          <td>
            <div class="checkbox">
              <ui5-checkbox
                .id=${this.mode === 'Yearly' ? 'yearlyAvg' : 'monthlyAvg'}
                checked
                @change=${this._handleStatisticsChange}
              ></ui5-checkbox>
              ${this.mode}
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <ki-tceq-glossary-link
              link="STD"
              label="Standard Deviation"
            ></ki-tceq-glossary-link>
          </td>
          <td>
            <div class="checkbox">
              <ui5-checkbox
                id="dailySTD"
                checked
                @change=${this._handleStatisticsChange}
              ></ui5-checkbox>
              Daily
            </div>
          </td>
          <td>
            <div class="checkbox">
              <ui5-checkbox
                .id=${this.mode === 'Yearly' ? 'yearlySTD' : 'monthlySTD'}
                checked
                @change=${this._handleStatisticsChange}
              ></ui5-checkbox>
              ${this.mode}
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <ki-tceq-glossary-link
              link="Cap"
              label="Data Capture"
            ></ki-tceq-glossary-link>
          </td>
          <td>
            <div class="checkbox">
              <ui5-checkbox
                id="dailyCap"
                checked
                @change=${this._handleStatisticsChange}
              ></ui5-checkbox>
              Daily
            </div>
          </td>
          <td>
            <div class="checkbox">
              <ui5-checkbox
                .id=${this.mode === 'Yearly' ? 'yearlCap' : 'monthlyCap'}
                checked
                @change=${this._handleStatisticsChange}
              ></ui5-checkbox>
              ${this.mode}
            </div>
          </td>
        </tr>
      </table>`;
  }
}
