/* eslint-disable max-classes-per-file */
import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { Mix } from '@kisters/wcp-base/common';
import {
  i18nMixin,
  SM,
  ViewPort,
  responsiveMixin,
  getQueryObject,
} from '@kisters/wcp-base/decorators';
import '@kisters/wcp-base/components';
// eslint-disable-next-line import/extensions
import { repeat } from 'lit/directives/repeat.js';
import { uniqBy, orderBy, get } from 'lodash-es';
import '@ui5/webcomponents/dist/MultiComboBox';
import '@ui5/webcomponents/dist/ComboBox';
import nls from '../../locales/index';
import { Filter } from './Filter';

const getDisplayValue = (item: any, template: string): string => {
  let returnString = '';
  let placeholderString = '';
  let capturingPlaceholder = false;
  let lastCharDollar = false;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < template.length; i++) {
    const currentChar = template.charAt(i);

    if (!capturingPlaceholder && !lastCharDollar && currentChar !== '$') {
      returnString = `${returnString}${currentChar}`;
    } else if (!capturingPlaceholder && lastCharDollar && currentChar !== '{') {
      returnString = `${returnString}$${currentChar}`;
    } else if (!capturingPlaceholder && lastCharDollar) {
      capturingPlaceholder = true;
    } else if (capturingPlaceholder && currentChar === '}') {
      returnString = `${returnString}${item[placeholderString]}`;
      capturingPlaceholder = false;
      placeholderString = '';
    } else if (capturingPlaceholder) {
      placeholderString = `${placeholderString}${currentChar}`;
    }

    lastCharDollar = currentChar === '$';
  }

  return returnString;
};

@customElement('ki-wcp-wwp-filter')
export default class KiWcpWwpFilter extends Mix(LitElement, responsiveMixin, [
  i18nMixin,
  { nls },
]) {
  // language=CSS
  static styles = css`
    :host {
      display: flex;
    }

    ui5-multi-combobox,
    ui5-combobox {
      --_ui5_input_icon_padding: 11px;
      --_ui5_input_height: 40px;
      margin-right: 5px;
      flex: 1;
      border: 0;
      border-bottom: 1px solid var(--sapField_BorderColor);
      max-width: 250px;
    }

    ki-icon-btn.active,
    ki-icon-btn.selected {
      background-color: var(--theme-color-primary, #1d1d1b);
      color: white;
    }

    .hide {
      display: none;
    }

    .list-item {
      padding: 10px 20px;
      color: rgb(74, 74, 73);
      font-size: 1.2em;
    }

    .clear-container {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .clear-icon {
      margin-right: 2px;
      cursor: pointer;
      fill: #7e7e7e;
      width: 12px;
    }

    ui5-combobox:not([value]) .clear-icon,
    ui5-combobox[value=''] .clear-icon,
    ui5-multi-combobox:not([value]) .clear-icon,
    ui5-multi-combobox[value=''] .clear-icon {
      display: none;
    }

    :host(.sm-screen) ki-icon-btn {
      margin-right: 10px;
    }

    :host(.sm-screen) ui5-combobox,
    :host(.md-screen) ui5-combobox,
    :host(.sm-screen) ui5-multi-combobox,
    :host(.md-screen) ui5-multi-combobox {
      margin-bottom: 5px;
    }
  `;

  constructor() {
    super();
    this.filters = [];
    this.favorites = [];
  }

  static get properties() {
    return {
      activeFilter: { type: String, default: '{}' },
      filters: { type: Array, default: [] },
      stations: { type: Array, default: [] },
      favorites: { type: Array },
      favsactive: { type: Boolean },
    };
  }

  // Hide favorite button
  @property({ type: Boolean })
  hideFavorite = false;

  get favOn() {
    let activeFilter = {};
    try {
      activeFilter = JSON.parse(this.activeFilter);
    } catch (e) {
      console.warn(`broken filter ${this.activeFilter}`);
    }
    return activeFilter.fav || false;
  }

  render() {
    // language=html
    return html` ${repeat(this.filters, item => this._getFilter(item))}
    ${!this.hideFavorite && this.favorites.length
      ? this._renderFavoriteBtn()
      : ''}`;
  }

  _renderFavoriteBtn() {
    return html`<ki-icon-btn
      toggle
      class="${this.favOn ? 'selected' : ''}"
      @click="${() => {
        this.favsactive = !this.favsactive;
        this._handleChanged();
      }}"
      icon="ki ki-star"
    ></ki-icon-btn>`;
  }

  _renderBtn(filt, activeFilter) {
    return html`
      <ki-icon-btn
        class="${activeFilter[filt.field] ? 'active' : ''}"
        icon="${filt.icon}"
        @click="${() => {
          if (activeFilter[filt.field]) {
            this.dispatchEvent(
              new CustomEvent('change', {
                detail: { value: {} },
              }),
            );
          } else {
            this.renderRoot
              .querySelector(`#${filt.field.replaceAll('.', '\\.')}`)
              .show();
          }
        }}"
      >
      </ki-icon-btn>
    `;
  }

  // eslint-disable-next-line class-methods-use-this
  _getFilter(filt: Filter) {
    const activeFilter = JSON.parse(this.activeFilter);
    if (ViewPort.size === SM && filt.icon) {
      return html`
        ${this._renderBtn(filt, activeFilter)}
        <ki-modal id="${filt.field}" closeable .label="${filt.label}">
          <div class="list">
            ${this.filterOptions(filt).map(
              option =>
                html`<div
                  class="list-item"
                  @click="${() => {
                    const filterValue = {};
                    filterValue[filt.field] = option.value;
                    this.renderRoot
                      .querySelector(`#${filt.field.replaceAll('.', '\\.')}`)
                      .close();
                    this.dispatchEvent(
                      new CustomEvent('change', {
                        detail: { value: filterValue },
                      }),
                    );
                  }}"
                >
                  ${option.displayValue}
                </div>`,
            )}
          </div>
        </ki-modal>
      `;
    }

    const isSelected = item =>
      activeFilter[filt.field] && activeFilter[filt.field].includes(item)
        ? 'selected'
        : '';

    switch (filt.type) {
      case 'multiselect':
        return html`<ui5-multi-combobox
          id="${filt.field}"
          placeholder="${filt.label}"
          @selection-change="${this._handleChangedMulti}"
        >
          ${this.filterOptions(filt).map(item =>
            isSelected(item.value)
              ? html`<ui5-mcb-item
                  selected
                  text="${item.displayValue}"
                  additional-text="${item.additionalText}"
                ></ui5-mcb-item>`
              : html`<ui5-mcb-item text="${item.displayValue}"></ui5-mcb-item>`,
          )}
        </ui5-multi-combobox>`;
      default:
        return html`<ui5-combobox
          clear-button-visible
          id="${filt.field}"
          placeholder="${filt.label}"
          value="${activeFilter[filt.field] || ''}"
          @change="${this._handleChanged}"
        >
          ${this.filterOptions(filt).map(
            item =>
              html`<ui5-cb-item
                text="${item.displayValue}"
                additional-text="${item.additionalText}"
              ></ui5-cb-item>`,
          )}
          <div class="clear-container" slot="icon">
            <ki-icon
              class="clear-icon"
              icon="ki ki-times"
              @click="${() => {
                const combobox = getQueryObject(
                  this,
                  `#${filt.field.replaceAll('.', '\\.')}`,
                );
                if (filt.linkedTo && typeof filt.linkedTo === 'string') {
                  console.warn(
                    'Filters should be provided as Array of strings',
                    filt,
                  );
                  filt.linkedTo = [filt.linkedTo]; // Backward compatibility (used to be string)
                }
                if (filt.linkedTo) {
                  filt.linkedTo.forEach(item => {
                    const node = this.renderRoot.querySelector(`#${item}`);
                    if (node.value) {
                      node.value = '';
                    }
                  });
                }

                combobox.value = '';
                this._handleChanged();
              }}"
            ></ki-icon>
          </div>
        </ui5-combobox>`;
    }
  }

  filterOptions({
    field,
    type,
    sort,
    displayTemplate,
    additionalText,
    sortBy,
    sortDir,
  }) {
    let _stations = uniqBy(
      type === 'multiselect' ? this.allStations : this.stations,
      field,
    );

    if (sortBy) {
      _stations = orderBy(_stations, sortBy, sortDir);
    }

    let data: Array<{
      value: string;
      additionalText: string;
      displayValue: string;
    }> = [];

    _stations.forEach(item => {
      const value = get(item, field);
      const addText = item[additionalText] ?? '';

      if (Array.isArray(value)) {
        value.forEach(val => {
          data.push({
            value: val,
            additionalText: addText,
            displayValue: displayTemplate
              ? getDisplayValue(item, displayTemplate)
              : val,
          });
        });
      } else {
        data.push({
          value,
          additionalText: item[additionalText] ?? '',
          displayValue: displayTemplate
            ? getDisplayValue(item, displayTemplate)
            : value,
        });
      }
    });

    data =
      data
        .filter(t => !!t.value)
        .filter(
          (t, index) =>
            data.findIndex(item => item.value === t.value) === index,
        ) || [];

    if (sort === 'numeric' && !sortBy) {
      data.sort((a, b) => {
        const numberVala = parseFloat(a.value);
        const numberValb = parseFloat(b.value);
        // eslint-disable-next-line no-restricted-globals
        if (!isNaN(numberVala) && !isNaN(numberValb)) {
          if (numberVala > numberValb) return 1;
          if (numberVala < numberValb) return -1;
          return 0;
        }

        if (a.value > b.value) return 1;
        if (a.value < b.value) return -1;
        return 0;
      });
    } else if (!sortBy) {
      data.sort(
        (a, b) => a.value.localeCompare && a.value.localeCompare(b.value),
      );
    }
    return data;
  }

  _resetValue() {
    this.value = '';
    this.renderRoot.querySelector('#input').value = '';
    this._handleChanged();
  }

  _handleChangedMulti() {
    const activeFilter = {
      fav: this.favsactive,
    };
    this.renderRoot.querySelectorAll('ui5-multi-combobox').forEach(node => {
      if (node.selectedValues.length) {
        activeFilter[node.id] = node.selectedValues.map(item => item.text);
      }
    });
    this.dispatchEvent(
      new CustomEvent('change', { detail: { value: activeFilter } }),
    );
  }

  _handleChanged() {
    const activeFilter = {
      fav: this.favsactive,
    };
    this.renderRoot.querySelectorAll('ui5-combobox').forEach(node => {
      if (node.value) activeFilter[node.id] = node.value;
    });
    this.dispatchEvent(
      new CustomEvent('change', { detail: { value: activeFilter } }),
    );
  }
}
