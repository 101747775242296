import { getConfig } from '@kisters/wcp-base/app';

export default {
  async cgiScript(script: string, data: any) {
    const config = getConfig();
    const cgiURL = config.cgiUrl;
    const formData = new FormData();
    formData.append('parameters', JSON.stringify(data));
    let response;
    // TODO: error handling
    try {
      response = await fetch(cgiURL + script, {
        method: 'post',
        body: formData,
      });
    } catch (error) {
      console.error(error);
      return error;
    }

    if (response?.ok) {
      const result = await response.json();
      console.log(result);
      return result;
    }

    console.log(`HTTP Response Code: ${response?.status}`);
    return `HTTP Response Code: ${response?.status}`;
  },
};
