import { css, html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
// eslint-disable-next-line import/extensions
import { property } from 'lit/decorators/property.js';
import { i18nMixin } from '@kisters/wcp-base/decorators';
import { getCurrentApi } from '@kisters/wiski-web/api';
import { Mix, downloadFile } from '@kisters/wcp-base/common';
import { getConfig } from '@kisters/wcp-base/app';
import KiResponsiveTable from '@kisters/wiski-web/components/ki-responsive-table/ki-responsive-table';
import BaseTable from '@kisters/wcp-base/components/ki-table/BaseTable';
import { get } from 'lodash-es';
import { template } from '@kisters/wcp-base/common/util';

import { repeat } from 'lit/directives/repeat.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import nls from '../locales/index';
import { sortCols } from '../services/helpers';

@customElement('ki-tceq-table')
export default class KiTceqTable extends Mix(BaseTable, [i18nMixin, { nls }]) {
  // TODO: small screens?
  // language=CSS
  static styles = [
    KiResponsiveTable.styles,
    css`
      .col-parameter {
        width: 150px;
      }
      .col-parameter div {
        justify-content: normal !important;
      }
      .cell {
        text-align: center;
        border: 1px solid rgba(128, 128, 128, 0.57);
        padding: 10px 5px;
        font-size: 15px;
      }
      .header-content {
        text-transform: capitalize;
        text-align: center;
        padding: 5px 0px;
      }
      .header-sub-content {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .table {
        box-sizing: border-box;
        width: fit-content;
        table-layout: auto;
      }
    `,
  ];

  @property({ type: Boolean })
  nested;

  @property()
  minWidth;

  @property()
  headerPadding;

  @property()
  qualityCodes;

  get renderingColumns() {
    let result = [];
    if (this.nested) {
      result =
        this.columns ||
        Object.keys(this.renderingData[0] || {}).map(key => {
          const [label, poc] = key.split('POC:');
          return {
            field: key,
            label,
            sortable: true,
            nested: key !== 'Date',
            poc,
          };
        });
    } else {
      result =
        this.columns ||
        Object.keys(this.renderingData[0] || {}).map(key => {
          const [label, poc] = key.split('POC:');
          return {
            field: key,
            label,
            sortable: true,
            poc,
          };
        });
    }
    sortCols(result);
    return result;
  }

  // eslint-disable-next-line class-methods-use-this
  _getMinMaxStyle(val) {
    if (!val) return '';
    let style = '';
    if (val.maximum) {
      style += 'font-weight: bold; text-decoration: underline;';
    } else if (val.minimum) {
      style +=
        'font-weight: bold; font-style: italic; text-decoration: underline;';
    }
    return style;
  }

  // eslint-disable-next-line class-methods-use-this
  _getCellStyle(val) {
    if (!val) return '';
    let style = '';
    if (val.status === 'Validated') {
      style += 'border-bottom: 3px solid green;';
    }
    if (val.samples) {
      style += 'display: flex; justify-content: space-evenly;';
    }
    return style;
  }

  // eslint-disable-next-line class-methods-use-this
  _renderCell(col, item) {
    const _val = get(item, col.field);
    if (!_val) {
      return html`<div
        style=${col.css || ''}
        class="cell col-${col.field}"
      ></div>`;
    }
    let val;
    if (typeof _val === 'object') {
      val = _val.value;
    } else val = _val;
    const prefix = col.prefix && val ? template(col.prefix, item) : '';
    const suffix = col.suffix && val ? template(col.suffix, item) : '';

    return col.renderCell
      ? col.renderCell(item)
      : html`
          <div style=${col.css || ''} class="cell col-${col.field}">
            <div style=${this._getCellStyle(_val)}>
              ${this.qualityCodes?.includes(val) || col.field === 'parameter'
                ? html`<ki-tceq-glossary-link
                    .label="${val}"
                    .link="${val}"
                  ></ki-tceq-glossary-link>`
                : html`<span style=${this._getMinMaxStyle(_val)}
                    >${prefix}${val}${suffix}</span
                  >`}
              <span>${prefix}${_val.samples}${suffix}</span>
            </div>
          </div>
        `;
  }

  _renderHeader() {
    return html`
      <div class="header">
        <div class="row">
          ${repeat(
            this.renderingColumns,
            (col: { field: any }) => col.field,
            col => this._renderHeaderCell(col),
          )}
        </div>
      </div>
    `;
  }

  // eslint-disable-next-line class-methods-use-this
  _formatHeaderSubContent(label) {
    switch (label.toLowerCase()) {
      case 'parameter':
      case 'day':
      case 'date':
      case 'time':
      case '00:00':
      case '01:00':
      case '02:00':
      case '03:00':
      case '04:00':
      case '05:00':
      case '06:00':
      case '07:00':
      case '08:00':
      case '09:00':
      case '10:00':
      case '11:00':
      case '12:00':
      case '13:00':
      case '14:00':
      case '15:00':
      case '16:00':
      case '17:00':
      case '18:00':
      case '19:00':
      case '20:00':
      case '21:00':
      case '22:00':
      case '23:00':
        return unsafeHTML(label);
      default:
        return html`<ki-tceq-glossary-link
          .label="${label}"
          .link="${label.trim()}"
        ></ki-tceq-glossary-link>`;
    }
  }

  // The ignore is important because prettier inserts whitespace which the table renders
  // prettier-ignore
  // eslint-disable-next-line class-methods-use-this
  _renderHeaderCell(col) {
    if (col.nested) {
      return col.renderHeaderCell
      ? col.renderHeaderCell()
      : html`
          <div
            class="cell col-${col.field}"
            style=${col.width ? `width:${col.width - 15}px;` : ''}
          >
            <div
              style="text-align: center;"
              class="header-content"
              title="${col.title || col.label}"
            ><span class="col-label">
              <div class="header-sub-content">
                ${this._formatHeaderSubContent(col.label)}
              </div>
              <div style="color: gray; font-size: small;">
                ${col.poc && html`
                  <ki-tceq-glossary-link
                    link="POC"
                    .label="${`POC: ${col.poc}
                `}">`}
              </div>
            </span>
            </div>
            <div style="display: flex; justify-content: space-evenly;">
              <span>Values</span>

              <span>Samples</span>
            </div>
          </div>
        `;
    }
    return col.renderHeaderCell
      ? col.renderHeaderCell()
      : html`
          <div
            class="cell col-${col.field}"
            style=${col.width ? `width:${col.width - 15}px;` : ''}
          >
            <div
              style=${`${col.labelCss || col.css || ''}`} 
              class="header-content"
              title="${col.title || col.label}"
            ><span class="col-label">
            <div class="header-sub-content">
                ${this._formatHeaderSubContent(col.label)}
              </div>
                <div style="color: gray; font-size: small;">
                  ${col.poc && html`
                  <ki-tceq-glossary-link
                    link="POC"
                    .label="${`POC: ${col.poc}
                `}">`}
                </div>
              </span> 
            </div>
          </div>
        `;
  }

  _getTableStyle() {
    if (this.minWidth) {
      return `min-width: ${this.minWidth};`;
    }
    if (!this.renderingData.length) {
      return '';
    }
    const l = Object.keys(this.renderingData[0]).length;
    return `min-width: ${175 * l}px;`;
  }

  handleDownload(additionalRows = []) {
    const separator = ',';
    const headers = Object.keys(this.data[0]);
    sortCols(headers);
    const headerRow = headers.join(separator);
    const rows = this.data.map(row =>
      headers
        .map(col => {
          const val = row[col];
          if (typeof val === 'object' && val !== null) return val.value;
          return val;
        })
        .join(separator),
    );
    const csv = [...additionalRows, headerRow, ...rows].join('\n');
    downloadFile(csv, 'tceq_data_download');
  }

  render() {
    if (!this.data.length || !this.data[0])
      return html`<div
        style="width: 70vw; height: 100%;display:flex;justify-content:center;align-items:center;"
      >
        <div>No data found</div>
      </div>`;
    // language=html
    return html`
      <div class="table" part="table" style=${this._getTableStyle()}>
        ${this._renderHeader()} ${this._renderData()}
      </div>
    `;
  }
}
