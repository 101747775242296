import { css, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { Mix } from '@kisters/wcp-base/common';
import { i18nMixin } from '@kisters/wcp-base/decorators';
import { groupBy } from 'lodash-es';
import WwMapPopup from '@kisters/wiski-web/components/common/ww-map-popup';
import nls from '../locales/index';

// eslint-disable-next-line import/extensions

@customElement('ki-tceq-map-popup')
export default class KiTceqMapPopup extends Mix(WwMapPopup, [
  i18nMixin,
  { nls },
]) {
  static styles = [
    WwMapPopup.styles,
    css`
      :host {
        display: block;
        z-index: 99;
      }
    `,
  ];

  _renderLegacy() {
    return html`${this.stations &&
      this.stations[0] &&
      this.stations[0].ts_values &&
      Object.keys(this.stations[0].ts_values).length > 1
        ? this._valueTmpl()
        : Object.values(groupBy(this.stations, 'station_id')).map(station =>
            this._stationTmpl(station),
          )}
      <div id="chartcontainer" style="display:none"></div>`;
  }
}
